import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { sendOtp, verifyOtp, resetPassword } from '../services/authService'; // Adjust the path as per your file structure

const ForgotPassword = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(30);

  const handleSendOTP = async () => {
    setLoading(true);
    try {
      const response = await sendOtp(phoneNumber);
      console.log('Send OTP response:', response);
      setOtpSent(true);
      setResendDisabled(true);
      setCountdown(30);
      setError('');
      setSuccessMessage('OTP sent successfully. Please check your phone.');
    } catch (error) {
      console.error('Error sending OTP:', error);
      setError('Failed to send OTP. Please try again.');
      setSuccessMessage('');
    }
    setLoading(false);
  };

  const handleVerifyOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await verifyOtp(phoneNumber, otp);
      console.log('Verify OTP response:', response);
      setSuccessMessage('OTP verified successfully.');
      setError('');
    } catch (error) {
      console.error('Error verifying OTP:', error);
      setError('Failed to verify OTP. Please try again.');
      setSuccessMessage('');
    }
    setLoading(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await resetPassword(phoneNumber, otp, newPassword);
      console.log('Reset password response:', response);
      setSuccessMessage('Password reset successfully.');
      setError('');
      // Optionally redirect to login page after password reset
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('Failed to reset password. Please try again.');
      setSuccessMessage('');
    }
    setLoading(false);
  };

  const handleResendOTP = async () => {
    setResendDisabled(true);
    setCountdown(30);
    try {
      const response = await sendOtp(phoneNumber);
      console.log('Resend OTP response:', response);
      setOtpSent(true);
      setResendDisabled(true);
      setSuccessMessage('OTP resent successfully. Please check your phone.');
      setError('');
    } catch (error) {
      console.error('Error resending OTP:', error);
      setError('Failed to resend OTP. Please try again.');
      setSuccessMessage('');
    }
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 font-[sans-serif] h-full md:min-h-screen p-4">
      <div className="grid justify-center max-w-md mx-auto">
        <div className="bg-white rounded-2xl p-6 -mt-24 relative w-[400px] z-10 shadow-[0_2px_16px_-3px_rgba(6,81,237,0.3)]">
          <h2 className="text-3xl font-extrabold text-blue-600 mb-6">Forgot Password</h2>
          <form onSubmit={otpSent ? handleResetPassword : handleSendOTP}>
            {!otpSent ? (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Phone Number</label>
                <input
                  type="tel"
                  className="w-full px-3 py-2 border-b border-gray-300 focus:border-blue-600 outline-none"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
            ) : (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Enter OTP</label>
                <input
                  type="text"
                  className="w-full px-3 py-2 border-b border-gray-300 focus:border-blue-600 outline-none"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
              </div>
            )}
            {otpSent && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">New Password</label>
                <input
                  type="password"
                  className="w-full px-3 py-2 border-b border-gray-300 focus:border-blue-600 outline-none"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>
            )}
            {!otpSent ? (
              <button
                type="button"
                onClick={handleSendOTP}
                className={`w-full py-2.5 px-4 text-sm font-semibold rounded-full text-white ${
                  loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                } focus:outline-none`}
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send OTP'}
              </button>
            ) : (
              <button
                type="submit"
                className={`w-full py-2.5 px-4 text-sm font-semibold rounded-full text-white ${
                  loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
                } focus:outline-none`}
                disabled={loading}
              >
                {loading ? 'Resetting...' : 'Reset Password'}
              </button>
            )}
            {otpSent && (
              <div className="mt-2 text-center text-sm">
                <p>Resend OTP in {countdown} seconds</p>
                <button
                  className={`text-blue-600 hover:underline focus:outline-none ${
                    resendDisabled ? 'cursor-not-allowed text-gray-500' : ''
                  }`}
                  onClick={handleResendOTP}
                  disabled={resendDisabled || loading}
                >
                  Resend OTP
                </button>
              </div>
            )}
            {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
            {successMessage && <p className="text-green-500 mt-2 text-center">{successMessage}</p>}
          </form>
          <div className="mt-8 text-center text-sm">
            <Link to={'/login'} className="text-blue-600 hover:underline">
              Back to Login
            </Link>
          </div>
        </div>
        <img
          src="https://readymadeui.com/login-image.webp"
          className="absolute bottom-0 left-0 w-full object-cover"
          alt="login-image"
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
