import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { FaCartPlus } from "react-icons/fa";
import { ImEye } from "react-icons/im";
import { HiOutlineHeart } from "react-icons/hi";
import Swal from "sweetalert2";
import StarRating from "../components/StarRating";

const Home = ({ appliedFilters, updateCartCount }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [noResults, setNoResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      const searchParams = new URLSearchParams(location.search);
      const category = searchParams.get("category");
      const query = searchParams.get("query");
      const priceRange = searchParams.get("priceRange");
      const condition = searchParams.get("condition");
      const filters = {
        category,
        priceRange,
        condition,
        ...appliedFilters,
      };
    console.log(appliedFilters);


      try {
        let fetchedProducts;
        if (query) {
          setSearchQuery(query);
          fetchedProducts = await fetchProductsByQuery(query);
        } else if (category) {
          fetchedProducts = await fetchProductsByCategory(category);
        } else if (Object.keys(filters).some(key => filters[key] !== null && filters[key] !== "")) {
          fetchedProducts = await fetchFilteredProducts(filters);
        } else {
          fetchedProducts = await fetchAllProducts();
        }

        setProducts(Array.isArray(fetchedProducts) ? fetchedProducts : []);
       console.log(fetchedProducts);
       
        setNoResults(fetchedProducts.length === 0);
      } catch (error) {
        console.error("Error fetching products:", error.message);
        setProducts([]);
        setNoResults(true);
      }

      const loginData = JSON.parse(localStorage.getItem("loginData"));
      if (loginData) {
        const loginTime = new Date(loginData.timestamp);
        const currentTime = new Date();
        const diffInHours = (currentTime - loginTime) / (1000 * 60 * 60); // Convert milliseconds to hours
        if (diffInHours >= 1) {
          localStorage.removeItem("authToken");
          localStorage.removeItem("user");
          localStorage.removeItem("loginData");
          navigate("/login"); // Redirect to login after clearing auth data
        }
      }
    };

    fetchProducts();
  }, [location.search, appliedFilters, navigate]);

  const fetchFilteredProducts = async (filters) => {
    try {
      const response = await axios.get("http://localhost:5000/api/products", {
        params: filters,
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error("Error fetching filtered products:", error.message);
      return [];
    }
  };

  const fetchProductsByCategory = async (category) => {
    try {
      const response = await axios.get(`http://localhost:5000/api/products/category/${category}`);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error("Error fetching products by category:", error.message);
      return [];
    }
  };

  const fetchProductsByQuery = async (query) => {
    try {
      const response = await axios.get(`http://localhost:5000/api/products/search/${query}`);
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error("Error fetching products by query:", error.message);
      return [];
    }
  };

  const fetchAllProducts = async () => {
    try {
      const response = await axios.get("http://localhost:5000/api/products");
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      console.error("Error fetching all products:", error.message);
      return [];
    }
  };

  const handleAddToCart = async (productId) => {
    try {
      await axios.post(
        "http://localhost:5000/api/cart/add",
        { productId: productId, quantity: 1 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Product added to cart successfully!',
      });
      // Optionally update cart count here
      // updateCartCount();
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add product to cart.',
      });
    }
  };

  const handleBuyNow = async (productId) => {
    try {
      await axios.post(
        "http://localhost:5000/api/cart/add",
        { productId: productId, quantity: 1 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      navigate('/buy-now');
      // Optionally update cart count here
      // updateCartCount();
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add product to cart.',
      });
    }
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <div className="container mx-auto p-4">
      {noResults ? (
        <p className="text-center text-gray-600">Product not found</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {products.map((product) => (
            <div
              key={product._id}
              className="rounded-lg border border-gray-200 bg-white p-6 shadow-sm"
            >
              <div className="h-56 w-full mb-4">
                <img
                  src={product.images[0]}
                  alt={product.title}
                  className="h-full w-full object-cover"
                />
              </div>
              <div>
                <div className="mb-4 flex items-center justify-between gap-4">
                  <span className="rounded bg-blue-300 px-2.5 py-0.5 text-xs font-bold text-blue-900">
                    Up to {product.discountPercentage}% off
                  </span>
                  <div className="flex items-center gap-1">
                    <button
                      type="button"
                      aria-label="Quick look"
                      onClick={() => handleProductClick(product._id)}
                      className="rounded-lg p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                    >
                      <ImEye />
                    </button>
                    <button
                      type="button"
                      aria-label="Add to Favorites"
                      className="rounded-lg p-2 text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                    >
                      <HiOutlineHeart />
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => handleProductClick(product._id)}
                  className="text-lg font-semibold leading-tight text-gray-900 hover:underline"
                >
                  {product.title}
                </button>
                <div className="mt-2 flex items-center gap-2">
                  <div className="flex items-center">
                    <StarRating rating={product.rating} />
                  </div>
                  <span className="ml-2 text-xs font-medium text-gray-500">
                    {parseFloat(product.rating).toFixed(2)}
                  </span>
                </div>
                <div className="mt-4 flex items-center justify-between">
                  <span className="text-lg font-bold text-gray-900">
                    ₹{product.price}
                  </span>
                  <div className="flex gap-2">
                    <button
                      onClick={() => handleAddToCart(product._id)}
                      className="py-2.5 px-5 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 flex items-center"
                    >
                      <FaCartPlus className="mr-2" />
                    </button>
                    <button
                      onClick={() => handleBuyNow(product._id)}
                      className="text-md font-medium inline-flex items-center rounded-lg bg-gradient-to-br from-purple-600 to-blue-500 px-5 py-2.5 text-center font-medium text-white hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300"
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Home;
