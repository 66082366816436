import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const OrderStatus = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3); // Countdown time in seconds

  useEffect(() => {
    // Set up a timer to update the countdown
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);

    // Redirect after 3 seconds
    const redirectTimer = setTimeout(() => {
      navigate('/');
    }, 3000);

    // Cleanup timers on component unmount
    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  const handleManualRedirect = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center bg-purple-800 text-white py-12 px-6">
      <h1 className="text-3xl font-bold mb-2">MyLapKart</h1>
      <div className="flex items-center mb-4">
        <h2 className="text-4xl font-bold">Your order is on the way!</h2>
        <div className="ml-4 w-10 h-10 bg-yellow-500 flex items-center justify-center rounded-full">
          <svg
            className="w-6 h-6 text-purple-800"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M5 13l4 4L19 7"
            ></path>
          </svg>
        </div>
      </div>
      <p className="text-center mb-8">
        Great news! Your order is all set to hit the road. We're packing it up
        with care and it'll be on its way to you in no time.
      </p>
      <div className="flex justify-around w-full max-w-md mb-8">
        <div className="flex flex-col items-center">
          <div className="w-12 h-12 flex items-center justify-center bg-yellow-500 rounded-full mb-2">
            {/* Confirmed Icon */}
            <svg
              className="w-6 h-6 text-purple-800"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
          <span>Confirmed</span>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-12 h-12 flex items-center justify-center bg-purple-600 rounded-full mb-2">
            {/* Shipping Icon */}
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 19l-9 9M3 10l9-9m0 0l9 9m-9 9l9-9"
              ></path>
            </svg>
          </div>
          <span>Shipping</span>
        </div>
        <div className="flex flex-col items-center">
          <div className="w-12 h-12 flex items-center justify-center bg-purple-600 rounded-full mb-2">
            {/* Delivered Icon */}
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              ></path>
            </svg>
          </div>
          <span>Delivered</span>
        </div>
      </div>
      <p className="text-center mb-8">
        You will be redirected to the home page in {countdown} seconds.
      </p>
      <button
        onClick={handleManualRedirect}
        className="bg-yellow-500 text-purple-800 font-bold py-2 px-4 rounded-full hover:bg-yellow-600 transition duration-300"
      >
        Go to Home Now
      </button>
    </div>
  );
};

export default OrderStatus;
