import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Filter from './components/Filter';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import PageNotFound from './pages/PageNotFound';
import VerifyOtp from './components/VerifyOtp';
import ForgotPassword from './components/ForgotPassword';
import Settingpage from './pages/Settingpage';
import BuyOnEmi from './components/BuyOnEmi';
import UploadProduct from './components/UploadProduct';
import Cart from './components/Cart';
import ProductDetails from './components/ProductDetails';
import BuyNow from './components/BuyNow';
import OrderStatus from './components/OrderStatus';
import ViewOrderDetails from './components/ViewOrderDetails';
import Dashboard from './pages/Dashboard';
import PrivacyPolicy from './components/PrivacyPolicy';
import CancellationAndRefundPolicy from './components/CancellationAndRefundPolicy';
import ShippingPolicy from './components/ShippingPolicy';
import ContactUs from './components/ContactUs';
import TermsAndConditions from './components/TermsAndConditions';
import SellerLogin from './components/Seller/SellerLogin';
import SellerRegister from './components/Seller/SellerRegister';
import SellerForgotPassword from './components/Seller/SellerForgotPassword';
import SellerDashboard from './components/Seller/SellerDashboard';
import SellerLayout from './components/Seller/SellerLayout';
import Product from './components/Seller/Product';
import Invoice from './components/Seller/Invoice';
import User from './components/Seller/User';
import ProductStock from './components/Seller/ProductStock';
import OrderList from './components/Seller/OrderList';
import Settings from './components/Seller/Settings';
import SellerPayments from './components/Seller/SellerPayments';
import AddSlide from './components/Seller/AddSlide';
import Category from './components/Seller/Category';
import ProductComponent from './components/Seller/ProductComponent';

const shouldShowFilter = (pathname) => {
  // Show Filter on the home page and any other specific routes
  return pathname === '/' || pathname === '/home';
};

// Include all routes that shouldn't display the navbar
const noNavbarRoutes = [
  '/login',
  '/register',
  '/verify-otp',
  '/forgot-password',
  '/seller/login',
  '/seller/register',
  '/seller/forgot-password',
  '/seller/dashboard',
  '/seller/product',
  '/seller/settings',
  '/seller/order-list',
  '/seller/product-stock',
  '/seller/user-list',
  '/seller/invoice',
  '/seller/payments',
  '/seller/slides',
  '/seller/category'
];

function App() {
  const [appliedFilters, setAppliedFilters] = useState({});
  const location = useLocation();

  const handleApplyFilters = (filters) => {
    setAppliedFilters(filters);
  };

  const showNavbar = !noNavbarRoutes.some(route => location.pathname.startsWith(route));

  return (
    <div className="min-h-screen">
      {showNavbar && <Navbar />}
      <div className="flex">
        {shouldShowFilter(location.pathname) && <Filter onApplyFilters={handleApplyFilters} />}
        <main className="flex-grow p-4">
          <Routes>
            <Route path="/" element={<Navigate to="/dashboard" />} />
            {/* Authentication Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/verify-otp" element={<VerifyOtp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            {/* Authenticated Routes */}
            <Route path="/home" element={<Home appliedFilters={appliedFilters} />} />
            <Route path="/addproduct" element={<UploadProduct />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cancellation-and-refund-policy" element={<CancellationAndRefundPolicy />} />
            <Route path="/shipping-policy" element={<ShippingPolicy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/product/:productId" element={<ProductDetails />} />
            <Route path="/buy-on-emi" element={<BuyOnEmi />} />
            <Route path="/buy-now" element={<BuyNow />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/order-confirmation" element={<OrderStatus />} />
            <Route path="/order/:orderId" element={<ViewOrderDetails />} />
            <Route path="/settings" element={<Settingpage />} />

            {/* Seller Component */}
            <Route path="/seller/login" element={<SellerLogin />} />
            <Route path="/seller/register" element={<SellerRegister />} />
            <Route path="/seller/forgot-password" element={<SellerForgotPassword />} />

            {/* Seller Authenticated Routes */}
            <Route path="/seller/dashboard" element={<SellerLayout><SellerDashboard /></SellerLayout>} />
            <Route path="/seller/product" element={<SellerLayout><ProductComponent /></SellerLayout>} />
            <Route path="/seller/settings" element={<SellerLayout><Settings /></SellerLayout>} />
            <Route path="/seller/order-list" element={<SellerLayout><OrderList /></SellerLayout>} />
            <Route path="/seller/product-stock" element={<SellerLayout><ProductStock /></SellerLayout>} />
            <Route path="/seller/user-list" element={<SellerLayout><User /></SellerLayout>} />
            <Route path="/seller/invoice" element={<SellerLayout><Invoice /></SellerLayout>} />
            <Route path="/seller/payments" element={<SellerLayout><SellerPayments /></SellerLayout>} />
            <Route path="/seller/slides" element={<SellerLayout><AddSlide /></SellerLayout>} />
            <Route path="/seller/category" element={<SellerLayout><Category /></SellerLayout>} />
            {/* Catch-all route for 404 Not Found */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
