import React from "react";
import { Link } from "react-router-dom";
import { FaApple, FaGooglePlay, FaMobileAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { IoIosTimer } from "react-icons/io";
import { FaRegAddressCard } from "react-icons/fa6";
import Swal from "sweetalert2";

const Footer = () => {
  const handleAppClick = () => {
    Swal.fire({
      title: "Coming Soon",
      text: "This feature is coming soon!",
      icon: "info",
      confirmButtonText: "OK"
    });
  };

  return (
    <footer className="bg-[#081828] relative">
      {/* Start Footer Middle */}
      <div className="py-10 md:py-20">
        <div className="container mx-auto px-4 md:px-8 lg:px-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10">
            {/* Single Widget */}
            <div className="text-center md:text-left">
              <h3 className="text-white text-lg font-semibold mb-8">Welcome to MyLapkart.</h3>
              <p className="text-white mb-4">
                Your premier destination for Quality-Assured laptops, MacBooks, iPhones, iPads, and a wide range of accessories. At MyLapkart, we believe in providing top-notch technology at affordable prices, giving you access to premium devices without breaking the bank.
              </p>
            </div>
            {/* End Single Widget */}

            {/* Single Widget */}
            <div className="text-center md:text-left">
              <h3 className="text-white text-lg font-semibold mb-8">Our Mobile App</h3>
              <ul>
                <li className="mb-4">
                  <button
                    onClick={handleAppClick}
                    className="flex items-center bg-white/10 p-3 rounded-md transition hover:bg-blue-600 w-full text-left"
                  >
                    <FaApple className="text-2xl mr-3" />
                    <div className="text-left">
                      <span className="text-sm block text-white">Download on the</span>
                      <span className="text-base font-medium block text-white">App Store</span>
                    </div>
                  </button>
                </li>
                <li>
                  <button
                    onClick={handleAppClick}
                    className="flex items-center bg-white/10 p-3 rounded-md transition hover:bg-blue-600 w-full text-left"
                  >
                    <FaGooglePlay className="text-2xl mr-3" />
                    <div className="text-left">
                      <span className="text-sm block text-white">Download on the</span>
                      <span className="text-base font-medium block text-white">Google Play</span>
                    </div>
                  </button>
                </li>
              </ul>
            </div>
            {/* End Single Widget */}

            {/* Single Widget */}
            <div className="text-center md:text-left">
              <h3 className="text-white text-lg font-semibold mb-8">Important Links</h3>
              <ul className="text-gray-300">
                <li className="mb-2"><Link to="/privacy-policy" className="hover:text-blue-600" target="_blank" rel="noopener noreferrer">Privacy Policy</Link></li>
                <li className="mb-2"><Link to="/cancellation-and-refund-policy" className="hover:text-blue-600" target="_blank" rel="noopener noreferrer">Cancellation and Refund Policy</Link></li>
                <li className="mb-2"><Link to="/shipping-policy" className="hover:text-blue-600" target="_blank" rel="noopener noreferrer">Shipping Policy</Link></li>
                <li className="mb-2"><Link to="/contact-us" className="hover:text-blue-600" target="_blank" rel="noopener noreferrer">Contact Us</Link></li>
                <li className="mb-2"><Link to="/terms-and-conditions" className="hover:text-blue-600" target="_blank" rel="noopener noreferrer">Terms and Conditions</Link></li>
              </ul>
            </div>
            {/* End Single Widget */}

            {/* Single Widget */}
            <div className="text-center md:text-left">
              <h3 className="text-white text-lg font-semibold mb-8">Contact Us</h3>
              <p className="text-gray-300 mb-4">
                <FaRegAddressCard className="inline-block mr-2" /> Shop No. 15, First Floor, Navkar Plaza,<br/>
                Waki Road, Jamner, Tal.: Jamner, Dist.: Jalgaon-424206, (IN-MH)
              </p>
              <p className="text-gray-300 mb-2">
                <FaMobileAlt className="inline-block mr-2" /> Phone: +91 72196 55222
              </p>
              <ul className="text-gray-300 mb-2">
                <li>
                  <IoIosTimer className="inline-block mr-2" />Monday-Friday: 9.00 am - 6.00 pm
                </li>
              </ul>
              <p className="text-gray-300">
                <MdOutlineMail className="inline-block mr-2" /><a href="mailto:support@mylapkart.com" className="hover:text-blue-600">support@mylapkart.com</a>
              </p>
            </div>
            {/* End Single Widget */}
          </div>
        </div>
      </div>
      {/* End Footer Middle */}
    </footer>
  );
};

export default Footer;
