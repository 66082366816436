import React, { useState, useEffect } from "react";
import axios from "axios";
import { FiSend } from "react-icons/fi";
import { AiFillPrinter } from "react-icons/ai";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const Invoice = () => {
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [loading, setLoading] = useState(true); // Added loading state
  const [error, setError] = useState(""); // Added error state

  useEffect(() => {
    // Fetch delivered orders when the component mounts
    const fetchDeliveredOrders = async () => {
      try {
        const token = localStorage.getItem("authToken"); // Example of getting token from local storage
        const response = await axios.get("http://localhost:5000/api/orders/orderlist", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Filter the orders with status 'Delivered'
        const deliveredOrders = response.data.filter(order => order.orderStatus === 'Delivered');
        console.log(deliveredOrders);
        
        setDeliveredOrders(deliveredOrders);
        setLoading(false);
      } catch (error) {
        setError("Error fetching delivered orders");
        setLoading(false);
      }
    };

    fetchDeliveredOrders();
  }, []);

  const handlePrint = () => {
    const input = document.getElementById("invoice-pdf");

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 size
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
      pdf.save("invoice.pdf");
    });
  };

  const handleSend = () => {
    // Implement sending functionality here (e.g., email the invoice)
    alert("Sending invoice...");
  };

  const calculateTotal = () => {
    return deliveredOrders.reduce((total, order) => {
      return total + order.orderItems.reduce((orderTotal, item) => orderTotal + (item.price * item.quantity), 0);
    }, 0).toFixed(2);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  const formatDate = (date) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  // Get current date
  const currentDate = new Date();

  // Calculate due date (30 days from current date)
  const dueDate = new Date();
  dueDate.setDate(dueDate.getDate() + 30);

  return (
    <section className="flex-grow p-4 overflow-auto">
      <h3 className="text-3xl text-gray-900 font-bold mb-4">
        Invoice
      </h3>
      <div
        id="invoice-pdf"
        className="max-w-4xl mx-auto p-8 bg-white shadow-lg rounded-lg mb-4"
      >
        <div className="mb-4">
          <div className="flex justify-between items-center mb-2">
            <div>
              <p>
                <strong>Invoice From:</strong>
              </p>
              <p>Virginia Walker</p>
              <p>9694 Krajcik Locks Suite 635</p>
            </div>
            <div>
              <p>
                <strong>Invoice To:</strong>
              </p>
              <p>Austin Miller</p>
              <p>Brookview</p>
            </div>
            <div>
              <p>
              <strong>Invoice Date:</strong> {formatDate(currentDate)} <br />

              </p>
              <p>
              <strong>Due Date:</strong> {formatDate(dueDate)}
              </p>
            </div>
          </div>
        </div>

        <table className="w-full text-left border-collapse mb-4">
          <thead>
            <tr>
              <th className="border-b-2 p-2">Serial No.</th>
              <th className="border-b-2 p-2">Order ID</th>
              <th className="border-b-2 p-2">Customer Name</th>
              <th className="border-b-2 p-2">Quantity</th>
              <th className="border-b-2 p-2">Base Cost</th>
              <th className="border-b-2 p-2">Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {deliveredOrders.flatMap((order, orderIndex) =>
              order.orderItems.map((item, itemIndex) => (
                <tr key={item._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <td className="border-b p-2">{orderIndex + 1}.{itemIndex + 1}</td>
                  <td className="border-b p-2">{order.orderID}</td>
                  <td className="border-b p-2">{order.customer.fullName}</td>
                  <td className="border-b p-2">{item.quantity}</td>
                  <td className="border-b p-2">${item.price.toFixed(2)}</td>
                  <td className="border-b p-2">${(item.price * item.quantity).toFixed(2)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>

        <div className="flex justify-between items-center mt-4">
          <div className="text-lg font-bold">Total: ${calculateTotal()}</div>
        </div>
      </div>

      <div className="flex justify-end space-x-4">
        <button
          onClick={handlePrint}
          className="flex items-center bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
        >
          <AiFillPrinter className="mr-2" /> Print
        </button>
        <button
          onClick={handleSend}
          className="flex items-center bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-700"
        >
          <FiSend className="mr-2" /> Send
        </button>
      </div>
    </section>
  );
};

export default Invoice;
