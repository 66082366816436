import React, { useState, useEffect } from "react";
import { AiOutlineCamera } from "react-icons/ai";
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';


const Settings = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    sellerName: "",
    sellerEmail: "",
    sellerJoinedDate: "",
    sellerRating: 0,
    sellerVerified: false
  });

  useEffect(() => {
    // Fetch seller settings from the backend
    const fetchSettings = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get('http://localhost:5000/api/sellers/me', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        console.log(data);

        setFormData({
          sellerName: data.sellerName || "",
          sellerEmail: data.sellerEmail || "",
          sellerJoinedDate: data.sellerJoinedDate || "",
          sellerRating: data.sellerRating || 0,
          sellerVerified: data.sellerVerified || false
        });
        setSelectedImage(data.logo || null);
      } catch (error) {
        console.error('Error fetching settings:', error.response?.data?.message || 'An unexpected error occurred');
      }
    };
    fetchSettings();
  }, []);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append('sellerName', formData.sellerName);
      formDataToSend.append('sellerEmail', formData.sellerEmail);
      formDataToSend.append('sellerJoinedDate', formData.sellerJoinedDate);
      formDataToSend.append('sellerRating', formData.sellerRating);
      formDataToSend.append('sellerVerified', formData.sellerVerified);
      if (selectedImage) {
        formDataToSend.append('logo', selectedImage);
      }

      const response = await axios.post('http://54.162.114.131:5000/api/sellers/settings', formDataToSend, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      toast.success('Settings updated successfully!');
    } catch (error) {
      toast.error('Error saving settings. Please try again.');
    }
  };

  return (
    <section className="flex-grow p-4 overflow-auto">
      <h3 className="text-[32px] text-[#202224] font-bold mb-8">General Settings</h3>
      <form onSubmit={handleSubmit} className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        {/* Logo Upload Section */}
        <div className="flex flex-col items-center mb-8 border p-8 rounded-lg">
          <div className="relative mb-4 w-24 h-24">
            {selectedImage ? (
              <img
                src={selectedImage}
                alt="Uploaded Logo"
                className="w-full h-full object-cover rounded-full"
              />
            ) : (
              <div className="w-full h-full bg-gray-200 rounded-full flex items-center justify-center">
                <AiOutlineCamera className="text-gray-500 text-3xl" />
              </div>
            )}
            <input
              id="file-upload"
              type="file"
              className="hidden"
              onChange={handleImageUpload}
            />
          </div>
          <label htmlFor="file-upload" className="text-blue-700 font-bold cursor-pointer">
            Upload Logo
          </label>
        </div>

        {/* Settings Form */}
        <div className="grid grid-cols-2 gap-8">
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sellerName">
              Seller Name
            </label>
            <input
              type="text"
              id="sellerName"
              name="sellerName"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter seller name"
              value={formData.sellerName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sellerEmail">
              Seller Email
            </label>
            <input
              type="email"
              id="sellerEmail"
              name="sellerEmail"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter seller email"
              value={formData.sellerEmail}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sellerJoinedDate">
              Joined Date
            </label>
            <input
              type="text"
              id="sellerJoinedDate"
              name="sellerJoinedDate"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter joined date"
              value={formData.sellerJoinedDate}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sellerRating">
              Seller Rating
            </label>
            <input
              type="number"
              id="sellerRating"
              name="sellerRating"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Enter seller rating"
              value={formData.sellerRating}
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sellerVerified">
              Verified
            </label>
            <input
              type="checkbox"
              id="sellerVerified"
              name="sellerVerified"
              className="mr-2"
              checked={formData.sellerVerified}
              onChange={(e) => setFormData({ ...formData, sellerVerified: e.target.checked })}
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-center mt-8">
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Save Settings
          </button>
        </div>
      </form>
      <ToastContainer />  
    </section>
  );
};

export default Settings;
