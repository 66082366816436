import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Preloader from './Loader/Preloader';

const ShoppingCart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authToken = localStorage.getItem('authToken');
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/cart/cartItem', {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setCartItems(response.data.items);
        console.log('Cart items:', response.data.items);
      } catch (error) {
        console.error('Error fetching cart items:', error);
        setError('Error fetching cart items. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, [authToken]);

  const handleRemoveItem = async (itemId) => {
    try {
      const response = await axios.delete(`http://localhost:5000/api/cart/remove/${itemId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      setCartItems(response.data.items);
      console.log('Updated cart items after removal:', response.data.items);
    } catch (error) {
      console.error('Error removing item:', error);
      if (error.response) {
        console.error('Server responded with:', error.response.data);
      }
    }
  };

  const handleIncreaseQuantity = async (itemId) => {
    try {
      await axios.post(
        `http://localhost:5000/api/cart/increase/${itemId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item._id === itemId
            ? { ...item, quantity: item.quantity + 1 }
            : item
        )
      );
    } catch (error) {
      console.error('Error increasing quantity:', error);
      setError('Error increasing quantity. Please try again.');
    }
  };

  const handleDecreaseQuantity = async (itemId) => {
    try {
      await axios.post(
        `http://localhost:5000/api/cart/decrease/${itemId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      setCartItems((prevItems) =>
        prevItems.map((item) =>
          item._id === itemId && item.quantity > 1
            ? { ...item, quantity: item.quantity - 1 }
            : item
        )
      );
    } catch (error) {
      console.error('Error decreasing quantity:', error);
      setError('Error decreasing quantity. Please try again.');
    }
  };

  if (loading) {
    return <Preloader />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <section className="bg-white py-8 antialiased md:py-16 dark:bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 2xl:px-0">
        <h2 className="text-xl font-semibold text-gray-900 sm:text-2xl dark:text-white">Shopping Cart</h2>
        <div className="mt-6 sm:mt-8 md:gap-6 lg:flex lg:items-start xl:gap-8">
          <div className="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
            {cartItems.length === 0 ? (
              <div className="flex flex-col items-center py-16">
              <div className="mt-16">
                <div className="">
                  <div className="flex flex-col items-center">
                    <img src="https://i.imgur.com/dCdflKN.png" width="130" height="130" className="mb-4" alt="Empty Cart" />
                    <h3 className="text-lg font-bold mb-2">Your Cart is Empty</h3>
                    <h4 className="text-md mb-4">Add something to make me happy</h4>
                    <button
                      onClick={() => navigate('/')}
                      className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
                    >
                      Continue Shopping
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            ) : (
              <div className="space-y-6">
                {cartItems.map((item) => (
                  <CartItem
                    key={item._id}
                    item={item}
                    onRemove={handleRemoveItem}
                    onIncrease={handleIncreaseQuantity}
                    onDecrease={handleDecreaseQuantity}
                  />
                ))}
              </div>
            )}
          </div>
          {cartItems.length > 0 && <OrderSummary cartItems={cartItems} navigate={navigate} />} {/* Pass navigate to OrderSummary */}
        </div>
      </div>
    </section>
  );
};

const CartItem = ({ item, onRemove, onIncrease, onDecrease }) => {
  const { productId, quantity } = item;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (productId) {
      setLoading(false);
    } else {
      setError('Product details not available.');
    }
  }, [productId]);

  if (loading) {
    return <div>Loading product details...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm md:p-6 dark:border-gray-700 dark:bg-gray-800">
      {productId && (
        <div className="space-y-4 md:flex md:items-center md:justify-between md:gap-6 md:space-y-0">
          <a href="#" className="shrink-0 md:order-1">
            <img className="h-20 w-20 dark:hidden" src={productId.images[0]} alt="Product" />
          </a>
          <div className="space-y-4 md:order-2 md:max-w-md">
            <a href="#" className="text-base font-medium text-gray-900 hover:underline dark:text-white">{productId.title}</a>
            <div className="flex items-center gap-4">
              <button
                type="button"
                onClick={() => onRemove(item._id)}
                className="inline-flex items-center text-sm font-medium text-red-600 hover:underline dark:text-red-500"
              >
                <svg
                  className="me-1.5 h-5 w-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L17.94 6M18 18L6.06 6"
                  />
                </svg>
                Remove
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between md:order-3 md:justify-end">
            <div className="flex items-center">
              <button
                type="button"
                id="decrement-button"
                data-input-counter-decrement="counter-input"
                className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                onClick={() => onDecrease(item._id)}
              >
                <svg
                  className="h-2.5 w-2.5 text-gray-900 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 2"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h16" />
                </svg>
              </button>
              <input
                type="text"
                id="counter-input"
                data-input-counter
                className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 dark:text-white"
                value={quantity}
                readOnly
              />
              <button
                type="button"
                id="increment-button"
                data-input-counter-increment="counter-input"
                className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                onClick={() => onIncrease(item._id)}
              >
                <svg
                  className="h-2.5 w-2.5 text-gray-900 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 1v16M1 9h16" />
                </svg>
              </button>
            </div>
            <p className="ml-4 text-sm font-semibold text-gray-900 dark:text-white">{productId.price}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const OrderSummary = ({ cartItems, navigate }) => {
  const calculateTotal = () => {
    return cartItems.reduce((total, item) => {
      const itemTotal = item.productId.price * item.quantity;
      return total + itemTotal;
    }, 0);
  };

  const total = calculateTotal();

  return (
    <div className="mt-8 lg:sticky lg:top-8 lg:mt-0">
      <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm md:p-6 dark:border-gray-700 dark:bg-gray-800">
        <h3 className="text-base font-medium text-gray-900 dark:text-white">Order Summary</h3>
        <ul className="space-y-1">
          <li className="flex items-center justify-between text-sm font-medium text-gray-900 dark:text-white">
            <p>Subtotal</p>
            <p>{total.toFixed(2)}</p>
          </li>
          <li className="flex items-center justify-between text-sm font-medium text-gray-900 dark:text-white">
            <p>Shipping</p>
            <p>0.00</p>
          </li>
          <li className="flex items-center justify-between text-sm font-medium text-gray-900 dark:text-white">
            <p>Estimated Tax</p>
            <p>0.00</p>
          </li>
        </ul>
        <div className="flex items-center justify-between border-t border-gray-200 pt-4 dark:border-gray-700">
          <p className="text-base font-semibold text-gray-900 dark:text-white">Order Total</p>
          <p className="text-base font-semibold text-gray-900 dark:text-white">{total.toFixed(2)}</p>
        </div>
        <div className="space-y-2">
          <button
           onClick={() => navigate('/buy-now')}
            type="button"
            className="inline-flex w-full items-center justify-center rounded-lg bg-blue-600 px-4 py-3 text-base font-semibold text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 dark:focus:ring-offset-gray-800"
          >
            Checkout
          </button>
          <button
            type="button"
            onClick={() => navigate('/')}
            className="inline-flex w-full items-center justify-center rounded-lg border border-transparent px-4 py-3 text-base font-semibold text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 dark:text-white dark:hover:bg-gray-700 dark:focus:ring-offset-gray-800"
          >
            Continue Shopping
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCart;
