import React, { useEffect, useState } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const HeroArea = () => {
  const [slides, setSlides] = useState([]);
  const [error, setError] = useState("");

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    centerMode: false,
    dots: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/sellers/slides");
        setSlides(response.data);
      } catch (error) {
        console.error("Error fetching slides:", error.message);
        setError("Error fetching slides.");
      }
    };
  
    fetchSlides();
  }, []);
  

  const hasSlides = slides.length > 0;

  return (
    <section className="relative bg-white mt-3">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="flex flex-wrap">
          {/* Main Slider */}
          <div className="w-full lg:w-2/3 mb-4 lg:mb-0 lg:pr-4">
            {hasSlides && (
              <div className="relative overflow-hidden">
                <Slider {...settings}>
                  {slides.map((slide, index) => (
                    <div key={index} className="relative">
                      <img
                        src={slide.image}
                        alt={slide.title || "Slide Image"}
                        className="w-full h-[300px] object-fill sm:h-[400px] md:h-[500px] lg:h-[600px] object-cover"
                      />
                      <div className="absolute inset-0 flex items-center justify-center p-4 text-center lg:text-left">
                        <div>
                          <h2 className="text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold text-white    ">
                            {slide.title}
                          </h2>
                          <p className="text-sm sm:text-base md:text-lg lg:text-xl mt-2 md:mt-4">
                            {slide.description}
                          </p>
                          {slide.price && (
                            <h3 className="mt-2 md:mt-4 text-lg md:text-xl font-bold text-yellow-600">
                              <span className="text-sm md:text-base font-semibold text-white mr-1">
                                {slide.priceLabel || "Price:"}
                              </span>
                              {slide.price}
                            </h3>
                          )}
                          <div className="mt-2 md:mt-4">
                            <Link
                              to={slide.link || "#"}
                              className="bg-blue-500 text-white px-3 py-1 md:px-4 md:py-2 rounded hover:bg-blue-600 transition"
                            >
                              {slide.buttonText || "Learn More"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
          {/* Sidebar */}
          <div className="w-full lg:w-1/3 flex flex-col gap-4">
            {/* Featured Product */}
            <div
              className="w-full bg-gray-200 h-[150px] sm:h-[200px] md:h-[250px] lg:h-[300px] relative text-white"
              style={{
                backgroundImage:
                  "linear-gradient(to bottom right, #FDFCFB, #E2D1C3)",
              }}
            >
              <div className="absolute inset-0 flex items-center justify-center p-4 text-left">
                <div>
                  <h2 className="text-sm sm:text-base md:text-lg lg:text-xl text-blue-600 font-semibold">
                    <span className="block text-xs sm:text-sm md:text-base font-medium text-gray-500">
                      New line required
                    </span>
                    iPhone 12 Pro Max
                  </h2>
                  <h3 className="text-base sm:text-lg md:text-xl lg:text-2xl text-blue-600 font-bold mt-1 sm:mt-2 md:mt-3 lg:mt-4">
                    ₹ 12599.99
                  </h3>
                </div>
              </div>
            </div>
            {/* Weekly Sale */}
            <div className="w-full bg-gray-200 h-[150px] sm:h-[200px] md:h-[250px] lg:h-[300px] p-4 flex items-center justify-center rounded">
              <div className="text-center">
                <h2 className="text-sm sm:text-base md:text-lg lg:text-xl font-bold">
                  Weekly Sale!
                </h2>
                <p className="mt-2 sm:mt-3 md:mt-4 lg:mt-5 text-xs sm:text-sm md:text-base">
                  Saving up to 50% off all online store items this week.
                </p>
                <div className="mt-2 sm:mt-3 md:mt-4 lg:mt-5">
                  <Link
                    to="/"
                    className="bg-blue-500 text-white px-3 py-1 sm:px-4 sm:py-2 md:px-6 md:py-3 lg:px-8 lg:py-4 rounded hover:bg-blue-600 transition"
                  >
                    Shop Now
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!hasSlides && !error && (
        <p className="text-center text-gray-500 mt-4">No slides available.</p>
      )}
      {error && <p className="text-center text-red-500 mt-4">{error}</p>}
    </section>
  );
};

export default HeroArea;
