import React from 'react';
import HeroArea from '../components/HeroArea';
import FeaturedCategories from '../components/FeaturedCategories';
import TrendingProduct from '../components/TrendingProduct';
import Banner from '../components/Banner';
import SpecialOffer from '../components/SpecialOffer';
import ShippingInfo from '../components/ShippingInfo';
import Footer from '../components/Footer';
import ScrollToTopButton from './ScrollToTopButton'; // Import the ScrollToTopButton component

function Dashboard() {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 bg-gray-200">
      <div className="mb-8">
        <HeroArea />
      </div>
      <div className="mb-8">
        <FeaturedCategories />
      </div>
      <div className="mb-8">
        <TrendingProduct />
      </div>
      <div className="mb-8">
        <Banner />
      </div>
      <div className="mb-8">
        <SpecialOffer />
      </div>
      <div className="mb-8">
        <ShippingInfo />
      </div>
      <div className="mb-8">
        <Footer />
      </div>
      <ScrollToTopButton /> {/* Include the ScrollToTopButton component */}
    </div>
  );
}

export default Dashboard;
