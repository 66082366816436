import React from 'react';

const MonthlySalesSummary = ({ totalSales, totalOrders, averageOrderValue }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-6 transform transition duration-500 ">
      <h4 className="text-lg md:text-xl lg:text-2xl font-bold text-gray-800 mb-4">
        Monthly Sales Summary
      </h4>
      <div className="text-gray-700 space-y-2">
        <p className="flex items-center justify-between">
          <span>Total Sales:</span>
          <span className="font-semibold text-lg text-green-600">₹{totalSales}</span>
        </p>
        <p className="flex items-center justify-between">
          <span>Number of Orders:</span>
          <span className="font-semibold text-lg text-blue-600">{totalOrders}</span>
        </p>
        <p className="flex items-center justify-between">
          <span>Average Order Value:</span>
          <span className="font-semibold text-lg text-purple-600">₹{averageOrderValue}</span>
        </p>
      </div>
    </div>
  );
};

export default MonthlySalesSummary;
