import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StarRating from './StarRating';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const TrendingProduct = () => {
  const [topProducts, setTopProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllProducts = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/products");
        console.log(response.data); // Check the response data
        const top12Products = Array.isArray(response.data) ? response.data.slice(0, 12) : [];
        console.log(top12Products); // Check the top12Products variable
        setTopProducts(top12Products);
      } catch (error) {
        console.error("Error fetching all products:", error.message);
      }
    };

    fetchAllProducts();
  }, []);

  const handleAddToCart = async (productId) => {
    console.log(productId);
    try {
      await axios.post(
        "http://localhost:5000/api/cart/add",
        { productId: productId, quantity: 1 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      // Show success alert message
      Swal.fire({
        icon: 'success',
        title: 'Product added to cart',
        text: 'The product was successfully added to your cart.',
        confirmButtonText: 'OK'
      });
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
      // Show error alert message
      Swal.fire({
        icon: 'error',
        title: 'Failed to add product',
        text: 'There was an issue adding the product to your cart. Please try again.',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleProductClick = (productId) => {
    navigate(`/product/${productId}`);
  };

  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold mb-2">Trending Products</h2>
          <p className="text-gray-600">
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {topProducts.length > 0 ? (
            topProducts.map((product) => {
              // Ensure necessary properties exist
              if (!product || !product._id || !product.images || !product.images[0] || !product.title || !product.price) {
                console.error('Product data is missing properties:', product);
                return null;
              }

              const originalPrice = product.price;
              const discountPercentage = product.discountPercentage || 0;
              const discountAmount = (originalPrice * discountPercentage) / 100;
              const discountedPrice = originalPrice - discountAmount;

              return (
                <div
                  key={product._id}
                  className="relative bg-white border border-gray-200 rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 p-4"
                >
                  <div className="relative group">
                    <img
                      src={product.images[0]} // Ensure this is a valid URL
                      alt={product.title}
                      className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-110"
                    />
                    {discountPercentage > 0 ? (
                      <span className="absolute top-0 left-0 px-3 py-1 text-xs font-semibold text-white bg-red-600 rounded-br-lg">
                        -{discountPercentage}%
                      </span>
                    ) : (
                      <span className="absolute top-0 left-0 px-3 py-1 text-xs font-semibold text-white bg-blue-600 rounded-br-lg">
                        New
                      </span>
                    )}
                    <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      <button
                        onClick={() => handleAddToCart(product._id)}
                        className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-blue-500 text-white py-3 px-6 rounded-lg hover:bg-blue-600"
                      >
                        Add to Cart
                      </button>
                    </div>
                  </div>
                  <div className="mt-4">
                    <button
                      onClick={() => handleProductClick(product._id)}
                      className="text-lg font-semibold leading-tight text-gray-900 hover:underline"
                    >
                      {product.title}
                    </button>
                    <div className="flex items-center mt-2">
                      <StarRating rating={product.rating || 0} />
                      <span className="text-gray-500 ml-2">{product.numReviews || 0} Review(s)</span>
                    </div>
                    <div className="mt-3">
                      <span className="text-xl font-bold text-blue-600">
                        ₹{discountedPrice.toFixed(2)}{" "}
                      </span>
                      {discountPercentage > 0 && (
                        <span className="text-sm text-gray-500 line-through ml-2">
                          ₹{originalPrice.toFixed(2)}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No products found</div>
          )}
        </div>
      </div>
    </section>
  );
};

export default TrendingProduct;
