import React, { useState } from 'react';
import axios from 'axios';

function SellerForgotPassword() {
  const [email, setEmail] = useState('');

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/seller/forgot-password', { email });
      alert('Password reset link sent to your email');
    } catch (error) {
      console.error('Forgot password error', error);
      alert('Failed to send password reset link. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form onSubmit={handleForgotPassword} className="bg-white p-6 rounded shadow-md w-full max-w-sm">
        <h2 className="text-2xl mb-4">Forgot Password</h2>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 p-2 w-full border rounded"
            required
          />
        </div>
        <button type="submit" className="w-full bg-purple-500 text-white py-2 px-4 rounded">
          Send Reset Link
        </button>
      </form>
    </div>
  );
}

export default SellerForgotPassword;
