import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';

const AddCategoryModal = ({ isOpen, onRequestClose, onCategoryAdded }) => {
  const [name, setName] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('/api/categories/add', { name });
      console.log('Category added:', response.data);
      onCategoryAdded();
      onRequestClose();
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="bg-white rounded-lg p-8 shadow-lg w-full max-w-lg mx-auto mt-20"
    >
      <h2 className="text-2xl font-bold mb-6">Add Category</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700">Category Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
            required
          />
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onRequestClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Add Category
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddCategoryModal;
