import React, { useEffect, useState } from 'react';
import axios from 'axios';
import StarRating from './StarRating';
import offerImage from './images/offer-image.jpg';
import { Link } from 'react-router-dom';

const SpecialOffer = () => {
  const [specialOfferProducts, setSpecialOfferProducts] = useState([]);
  const [timer, setTimer] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get("http://localhost:5000/api/products");
        console.log(response.data); // Check the response data
        const top3Products = Array.isArray(response.data) ? response.data.slice(0, 3) : [];
        setSpecialOfferProducts(top3Products); // Getting 3 products for the special offer section
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    // Timer set to 15th of the next month
    const now = new Date();
    const nextMonth = now.getMonth() + 1;
    const endTime = new Date(now.getFullYear(), nextMonth, 15).getTime();

    const updateTimer = () => {
      const now = new Date().getTime();
      const distance = endTime - now;

      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimer({ days, hours, minutes, seconds });

      if (distance < 0) {
        clearInterval(timerInterval);
        setTimer({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    };

    updateTimer();
    const timerInterval = setInterval(updateTimer, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  return (
    <section className="special-offer bg-gray-100 py-12">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-800">Special Offer</h2>
          <p className="mt-4 text-gray-600">
            Check out our special offers on these fantastic products.
          </p>
        </div>
        <div className="flex flex-wrap -mx-2">
          <div className="lg:w-2/3 md:w-full w-full px-2">
            <div className="flex flex-wrap -mx-2">
              {specialOfferProducts.map((topProduct, index) => (
                <div key={index} className="lg:w-1/3 md:w-1/3 w-full px-2 mb-4">
                  <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
                    <div className="relative">
                      <img src={topProduct.images[0]} alt={topProduct.title} className="w-full h-48 object-cover hover:scale-105" />
                      <div className="absolute inset-0 flex items-center justify-center opacity-0 hover:opacity-100 transition-opacity duration-300 bg-black bg-opacity-50">
                        <Link to={`/product/${topProduct._id}`} className="text-white py-2 px-4 bg-blue-500 rounded-lg hover:bg-blue-600">
                          Add to Cart
                        </Link>
                      </div>
                    </div>
                    <div className="p-4">
                      <span className="text-sm text-gray-500">{topProduct.category.name}</span>
                      <h4 className="text-lg font-semibold mt-1">
                        <Link to={`/product/${topProduct._id}`} className="text-gray-800 hover:text-blue-500">{topProduct.title}</Link>
                      </h4>
                      <div className="flex items-center mt-2">
                        <StarRating rating={topProduct.rating} />
                        <span className="ml-2 text-gray-500">{topProduct.rating}.0 Review(s)</span>
                      </div>
                      <div className="mt-3">
                        <span className="text-xl font-bold text-blue-500">RS {topProduct.price}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Offer Section */}
          <div className="lg:w-1/3 md:w-full w-full px-2">
            <div className="bg-white rounded-lg shadow-lg p-6">
              <div className="relative">
                <img src={offerImage} alt="Offer" className="w-full rounded-lg" />
                <span className="absolute top-4 right-4 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded-full">-50%</span>
              </div>
              <div className="mt-4">
                <h2 className="text-xl font-bold text-gray-800">
                  <Link to={`/product/66af712571523f48d92cebaf`} className="hover:text-blue-500">Bluetooth Headphone</Link>
                </h2>
                <div className="flex items-center mt-2">
                  {[...Array(5)].map((_, i) => (
                    <i key={i} className={`text-yellow-400 lni lni-star-filled ${i >= 4 ? 'text-gray-300' : ''} text-xs`}></i>
                  ))}
                  <span className="ml-2 text-gray-500">5.0 Review(s)</span>
                </div>
                <div className="mt-3">
                  <span className="text-xl font-bold text-blue-500">RS 200.00</span>
                  <span className="text-sm text-gray-500 line-through ml-2">RS 400.00</span>
                </div>
                <p className="mt-4 text-gray-600">Exclusive offer on Bluetooth Headphone with advanced features and premium sound quality.</p>
              </div>
              <div className="mt-6 border-t border-gray-200 pt-4">
                <div className="flex justify-between text-center">
                  <div className="w-1/4">
                    <h1 className="text-xl font-bold text-gray-800">{String(timer.days).padStart(2, '0')}</h1>
                    <h2 className="text-sm text-gray-500">Days</h2>
                  </div>
                  <div className="w-1/4">
                    <h1 className="text-xl font-bold text-gray-800">{String(timer.hours).padStart(2, '0')}</h1>
                    <h2 className="text-sm text-gray-500">Hours</h2>
                  </div>
                  <div className="w-1/4">
                    <h1 className="text-xl font-bold text-gray-800">{String(timer.minutes).padStart(2, '0')}</h1>
                    <h2 className="text-sm text-gray-500">Minutes</h2>
                  </div>
                  <div className="w-1/4">
                    <h1 className="text-xl font-bold text-gray-800">{String(timer.seconds).padStart(2, '0')}</h1>
                    <h2 className="text-sm text-gray-500">Seconds</h2>
                  </div>
                </div>
                <div className="mt-6 bg-red-600 text-white text-center py-4 rounded-lg">
                  <h1 className="text-lg font-bold">Event Ended Soon!</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpecialOffer;
