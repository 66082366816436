import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsMenuButtonWideFill } from 'react-icons/bs';
import { MdOutlineClose } from 'react-icons/md';
import Modal from 'react-modal'; // Importing Modal component

Modal.setAppElement('#root'); // Set the root element for accessibility

const Filter = ({ onApplyFilters }) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedPriceRange, setSelectedPriceRange] = useState('');
  const [selectedCondition, setSelectedCondition] = useState('');
  const [isVisible, setIsVisible] = useState(false); // Modal visibility state

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/categories');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error.message);
      }
    };
    fetchCategories();
  }, []);

  const handleApplyFilters = () => {
    onApplyFilters({
      category: selectedCategory,
      priceRange: selectedPriceRange,
      condition: selectedCondition,
    });
    setIsVisible(false); // Close modal after applying filters
  };

  const handleResetFilters = () => {
    setSelectedCategory('');
    setSelectedPriceRange('');
    setSelectedCondition('');
    onApplyFilters({
      category: '',
      priceRange: '',
      condition: '',
    });
    setIsVisible(false); // Close modal after resetting filters
  };

  return (
    <div className="relative">
      {/* Toggle button for mobile view */}
      <button
        className="block lg:hidden flex items-center text-black py-2 px-4 rounded-md mb-4 transition duration-300"
        onClick={() => setIsVisible(true)} // Open modal
      >
        {/* Filters */}
        <BsMenuButtonWideFill className="ml-2" />
      </button>

      {/* Modal for mobile view */}
      <Modal
        isOpen={isVisible}
        onRequestClose={() => setIsVisible(false)}
        contentLabel="Filter Modal"
        className="fixed inset-0 bg-white p-6 border border-gray-200 lg:hidden"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <button
          className="absolute top-4 right-4 text-gray-600"
          onClick={() => setIsVisible(false)}
        >
          <MdOutlineClose size={24} />
        </button>
        <h2 className="text-xl font-bold mb-4">Filter</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Category</label>
          <select
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select a category</option>
            {categories.map(category => (
              <option key={category._id} value={category._id}>{category.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Price Range</label>
          <select
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={selectedPriceRange}
            onChange={(e) => setSelectedPriceRange(e.target.value)}
          >
            <option value="">Select a price range</option>
            <option value="0,50">₹0 - ₹50</option>
            <option value="50,100">₹50 - ₹100</option>
            <option value="100,200">₹100 - ₹200</option>
            <option value="200,500">₹200 - ₹500</option>
            <option value="500,1000">₹500 - ₹1000</option>
            <option value="1000,2000">₹1000 - ₹2000</option>
            <option value="2000,5000">₹2000 - ₹5000</option>
            <option value="5000,50000">₹5000 - ₹50000</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Product Condition</label>
          <select
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={selectedCondition}
            onChange={(e) => setSelectedCondition(e.target.value)}
          >
            <option value="">Select Condition</option>
            <option value="new">New</option>
            <option value="refurbished">Refurbished</option>
          </select>
        </div>
        <div className="flex flex-col space-y-2">
          <button
            className="bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
            onClick={handleApplyFilters}
          >
            Apply
          </button>
          <button
            className="bg-gray-600 text-white py-2 rounded-md hover:bg-gray-700 transition duration-300"
            onClick={handleResetFilters}
          >
            Reset
          </button>
        </div>
      </Modal>

      {/* Filter panel for larger screens */}
      <div className="hidden lg:block bg-white p-4 border border-gray-200 lg:p-6 lg:border-none">
        <h2 className="text-xl font-bold mb-4">Filter</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Category</label>
          <select
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Select a category</option>
            {categories.map(category => (
              <option key={category._id} value={category._id}>{category.name}</option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Price Range</label>
          <select
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={selectedPriceRange}
            onChange={(e) => setSelectedPriceRange(e.target.value)}
          >
            <option value="">Select a price range</option>
            <option value="0,50">₹0 - ₹50</option>
            <option value="50,100">₹50 - ₹100</option>
            <option value="100,200">₹100 - ₹200</option>
            <option value="200,500">₹200 - ₹500</option>
            <option value="500,1000">₹500 - ₹1000</option>
            <option value="1000,2000">₹1000 - ₹2000</option>
            <option value="2000,5000">₹2000 - ₹5000</option>
            <option value="5000,50000">₹5000 - ₹50000</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Product Condition</label>
          <select
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300"
            value={selectedCondition}
            onChange={(e) => setSelectedCondition(e.target.value)}
          >
            <option value="">Select Condition</option>
            <option value="new">New</option>
            <option value="refurbished">Refurbished</option>
          </select>
        </div>
        <div className="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-2">
          <button
            className="w-full lg:w-1/2 bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition duration-300"
            onClick={handleApplyFilters}
          >
            Apply
          </button>
          <button
            className="w-full lg:w-1/2 bg-gray-600 text-white py-2 rounded-md hover:bg-gray-700 transition duration-300"
            onClick={handleResetFilters}
          >
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
