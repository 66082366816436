import axios from 'axios';

const API_URL = 'http://localhost:5000';

// Login function
export const login = async (phoneNumber, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      phoneNumber,
      password,
    });
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

// Register function
export const register = async (fullName, email, password, phoneNumber) => {
  try {
    const response = await axios.post(`${API_URL}/api/user`, {
      fullName,
      email,
      password,
      phoneNumber,
    });
    return response.data;
  } catch (error) {
    console.error('Error registering:', error);
    throw error;
  }
};

// Send OTP function
export const sendOtp = async (phoneNumber) => {
  try {
    const response = await axios.post(`${API_URL}/auth/send-otp`, { phoneNumber });
    return response.data;
  } catch (error) {
    console.error('Error sending OTP:', error);
    throw error;
  }
};

// Verify OTP function
export const verifyOtp = async (phoneNumber, otp) => {
  try {
    const response = await axios.post(`${API_URL}/auth/verify-otp`, { phoneNumber, otp });
    return response.data;
  } catch (error) {
    console.error('Error verifying OTP:', error);
    throw error;
  }
};


// Forgot Password function
export const forgotPassword = async (phoneNumber) => {
  try {
    const response = await axios.post(`${API_URL}/auth/forgot-password`, { phoneNumber });
    return response.data;
  } catch (error) {
    console.error('Error sending reset password request:', error);
    throw error;
  }
};

// Reset Password function
export const resetPassword = async (phoneNumber, otp, newPassword) => {
  try {
    const response = await axios.post(`${API_URL}/auth/reset-password`, {
      phoneNumber,
      otp,
      newPassword,
    });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};