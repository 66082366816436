import React from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function User() {
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('http://localhost:5000/api/users');
      if (response.status === 200) {
        setUsers(response.data);
      } else {
        toast.error(`Error fetching users: ${response.statusText}`);
      }
    } catch (error) {
      toast.error(`Error fetching users: ${error.message}`);
    }
  };

  return (
    <section className="flex-grow p-4 overflow-auto">
      <ToastContainer />
      <h3 className="text-3xl text-gray-900 font-bold mb-4">User Lists</h3>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border-gray-200 shadow-md rounded-lg overflow-hidden">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 text-left text-sm font-semibold text-gray-600 uppercase border-b">Profile</th>
              <th className="py-2 px-4 text-left text-sm font-semibold text-gray-600 uppercase border-b">Name</th>
              <th className="py-2 px-4 text-left text-sm font-semibold text-gray-600 uppercase border-b">Email</th>
              <th className="py-2 px-4 text-left text-sm font-semibold text-gray-600 uppercase border-b">Phone</th>
              <th className="py-2 px-4 text-left text-sm font-semibold text-gray-600 uppercase border-b">Addresses</th>
              <th className="py-2 px-4 text-left text-sm font-semibold text-gray-600 uppercase border-b">Status</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {users.map((user) => (
              <tr key={user._id}>
                <td className="py-2 px-4 text-sm font-medium text-gray-900">
                  <img
                    src={user.profileImg}
                    alt={user.fullName}
                    className="w-10 h-10 rounded-full"
                  />
                </td>
                <td className="py-2 px-4 text-sm font-medium text-gray-900">{user.fullName}</td>
                <td className="py-2 px-4 text-sm text-gray-600">{user.email}</td>
                <td className="py-2 px-4 text-sm text-gray-600">{user.phoneNumber}</td>
                <td className="py-2 px-4 text-sm text-gray-600">
                  {user.addresses.map((address, index) => (
                    <div key={index} className="mb-2">
                      {`${address.houseNumber}, ${address.buildingName}, ${address.streetName}, ${address.city}, ${address.state} ${address.zip}`}
                    </div>
                  ))}
                </td>
                <td className="py-2 px-4 text-sm text-gray-600">
                  <p className={`py-2 px-4 text-sm text-center ${user.active ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                    {user.active ? 'Active' : 'Inactive'}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default User;
