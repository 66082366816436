import React from "react";

const ShippingPolicy = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center mb-8">Shipping Policy</h2>
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">Introduction</h3>
          <p className="mb-4">Welcome to Mylapkart! We strive to deliver your orders promptly and efficiently. This Shipping Policy outlines the terms and conditions related to the shipping and delivery of products purchased from our online store.</p>
          <h3 className="text-lg font-semibold mb-4">Order Processing Time</h3>
          <p className="mb-4">All orders are processed within 1-2 business days. Orders are not processed on weekends or holidays. If we are experiencing a high volume of orders processing times may be delayed. We will notify you via email if there are significant delays in the processing of your order.</p>
          <h3 className="text-lg font-semibold mb-4">Shipping Rates & Delivery Estimates</h3>
          <p className="mb-4">Shipping charges for your order will be calculated and displayed at checkout. We offer various shipping options including standard and expedited shipping. Delivery estimates will be provided at checkout. Delivery times are estimated and are not guaranteed. Actual delivery times may vary based on factors beyond our control such as carrier delays or customs processing.</p>
          <h3 className="text-lg font-semibold mb-4">Shipping Destinations</h3>
          <p className="mb-4">We currently ship to addresses within India. For international shipping please contact our customer service team for more information on availability and rates.</p>
          <h3 className="text-lg font-semibold mb-4">Shipment Confirmation & Order Tracking</h3>
          <p className="mb-4">You will receive a shipment confirmation email once your order has shipped. This email will include tracking information allowing you to monitor the status of your delivery.</p>
          <h3 className="text-lg font-semibold mb-4">Customs Duties and Taxes</h3>
          <p className="mb-4">Mylapkart is not responsible for any customs duties taxes or fees incurred during the shipping process. These charges are the responsibility of the customer. Please check with your local customs office for more information on potential charges.</p>
          <h3 className="text-lg font-semibold mb-4">Damaged, Lost or Stolen Packages</h3>
          <p className="mb-4">Mylapkart is not liable for any products damaged lost or stolen during shipping. If your order arrives damaged please contact the shipment carrier to file a claim. Please save all packaging materials and damaged goods before filing a claim.</p>
          <h3 className="text-lg font-semibold mb-4">Address Changes</h3>
          <p className="mb-4">Please ensure that your shipping address is correct at the time of checkout. We are not responsible for orders shipped to incorrect addresses provided by the customer. If you need to change your shipping address after placing an order please contact us immediately. We will do our best to accommodate your request but we cannot guarantee changes once an order has been processed.</p>
          <h3 className="text-lg font-semibold mb-4">Contact Information</h3>
          <p className="mb-4">If you have any questions or concerns about our shipping policy please contact us at: <a href="mailto:support@mylapkart.com" className="text-blue-600">support@mylapkart.com</a></p>
          <h3 className="text-lg font-semibold mb-4">Policy Updates</h3>
          <p className="mb-4">We reserve the right to update our shipping policy as needed. Any changes will be posted on this page.</p>
        </div>
      </div>
    </section>
  );
};

export default ShippingPolicy;
