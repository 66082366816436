import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CgMenuGridR } from "react-icons/cg";
import logo from "./images/logo.png"
const Navbar = ({ cartItemCount }) => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [profileImg, setProfileImg] = useState('default-profile.png');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/user/me', {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
        });
        const fetchedUserData = response.data;
        setUserData(fetchedUserData);
        setProfileImg(fetchedUserData.profileImg || 'default-profile.png');
        localStorage.setItem('user', JSON.stringify(fetchedUserData));
      } catch (error) {
        console.error('Error fetching user details:', error);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          handleLogout();
        }
      }
    };

    fetchUserDetails();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleMenuClick = (menu) => {
    setSearchQuery('')
    setActiveMenu(menu);
    setIsMobileMenuOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    navigate(`/home?query=${searchQuery}`);
  };

  const userName = userData ? userData.fullName : 'Guest';

  const sharedClass =
    'text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center';

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/dashboard" className="flex-shrink-0" onClick={() => handleMenuClick('')}>
              {/* <span className="text-xl font-bold">
                <span className={`text-red-600 ${activeMenu === '' ? 'text-red-600' : 'text-red-600'}`}>
                  MyLap
                </span>
                <span className={`text-blue-600 ${activeMenu === '' ? 'text-blue-600' : 'text-blue-600'}`}>
                  Kart
                </span>
              </span> */}
              <img src={logo} className='w-24 h-24'/>
            </Link>
            <div className="ml-4 flex-1 hidden lg:flex justify-center">
              <form onSubmit={handleSearchSubmit} className="w-full">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                  placeholder="Search products..."
                />
              </form>
            </div>
          </div>
          <div className="flex space-x-4 items-center">
            <button
              onClick={() => setIsMobileMenuOpen(prev => !prev)}
              className="lg:hidden text-gray-800 hover:text-gray-600 focus:outline-none"
            >
              <CgMenuGridR className='w-6 h-6'/>
            </button>
            <div className="hidden lg:flex space-x-4">
              {['laptop', 'smartphone', 'accessories', 'tablet'].map((category) => (
                <Link
                  key={category}
                  to={`/home?category=${category}`}
                  className={` ${activeMenu === category ? `${sharedClass} bg-blue-600` : 'text-black p-2 rounded block flex items-center'}`}
                  onClick={() => handleMenuClick(category)}
                >
                  {category.charAt(0).toUpperCase() + category.slice(1)}
                </Link>
              ))}
            </div>
            <Link to="/cart" className="relative">
              <svg className="w-6 h-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 7M7 13l-1.6 8M17 13l1.6 8M17 13H7m10 0L17 9M9 21h6m-6 0a1 1 0 01-1-1m7 1a1 1 0 001-1m-8 0h8" />
              </svg>
              {cartItemCount > 0 && (
                <span className="absolute top-0 right-0 inline-block w-4 h-4 bg-red-600 text-white text-xs font-semibold text-center rounded-full">
                  {cartItemCount}
                </span>
              )}
            </Link>
            <div className="flex items-center relative">
              {userData ? (
                <div className="text-gray-800 cursor-pointer border border-gray-300 rounded p-2 flex items-center" onClick={toggleDropdown}>
                  <img className="w-10 h-10 rounded mr-2" src={profileImg} alt="Profile" />
                  <span>{userName}</span>
                  <svg className="h-6 w-6 text-gray-800 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                  </svg>
                </div>
              ) : (
                <Link to="/login" className={sharedClass}>
                  <span>Login</span>
                </Link>
              )}
              {isDropdownOpen && userData && (
                <div className="absolute top-full mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10">
                  <Link
                    to="/settings"
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 hover:text-blue-600 text-center"
                    onClick={() => setIsDropdownOpen(false)}
                  >
                    Profile
                  </Link>
                  <button
                    onClick={() => {
                      handleLogout();
                      setIsDropdownOpen(false);
                    }}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 hover:text-blue-600 w-full text-center"
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="lg:hidden px-4 pt-4 pb-2 space-y-2">
          <div className="flex justify-center">
            <form onSubmit={handleSearchSubmit} className="w-full">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                className="block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Search products..."
              />
            </form>
          </div>
          <div className="flex flex-col space-y-2">
            {['laptop', 'smartphone', 'accessory', 'tablet'].map((category) => (
              <Link
                key={category}
                to={`/home?category=${category}`}
                className={` ${activeMenu === category ? `${sharedClass} bg-blue-600` : 'text-black p-2 rounded block flex items-center'}`}
                onClick={() => handleMenuClick(category)}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </Link>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
