import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { toast } from 'react-toastify';

Modal.setAppElement('#root');

const AddProductModal = ({ isOpen, onRequestClose, categories, product }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState('');
  const [stock, setStock] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [condition, setCondition] = useState('');
  const [warrantyInformation, setWarrantyInformation] = useState('');
  const [shippingInformation, setShippingInformation] = useState('');
  const [returnPolicy, setReturnPolicy] = useState('');
  const [specifications, setSpecifications] = useState([{ key: '', value: '' }]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (product) {
      setTitle(product.title);
      setDescription(product.description);
      setCategory(product.category._id);
      setPrice(product.price);
      setDiscountPercentage(product.discountPercentage || '');
      setStock(product.stock);
      setBrand(product.brand);
      setModel(product.model);
      setCondition(product.condition);
      setWarrantyInformation(product.warrantyInformation || '');
      setShippingInformation(product.shippingInformation || '');
      setReturnPolicy(product.returnPolicy || '');
      setSpecifications(Object.entries(product.specifications || {}).map(([key, value]) => ({ key, value })));
      // Image handling can be added if needed
    }
  }, [product]);

  const handleAddSpecification = () => {
    setSpecifications([...specifications, { key: '', value: '' }]);
  };

  const handleRemoveSpecification = (index) => {
    const updatedSpecs = specifications.filter((_, i) => i !== index);
    setSpecifications(updatedSpecs);
  };

  const handleSpecificationChange = (index, field, value) => {
    const updatedSpecs = specifications.map((spec, i) =>
      i === index ? { ...spec, [field]: value } : spec
    );
    setSpecifications(updatedSpecs);
  };

  const handleImageChange = (e) => {
    setImages([...e.target.files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('category', category);
    formData.append('price', price);
    formData.append('discountPercentage', discountPercentage);
    formData.append('stock', stock);
    formData.append('brand', brand);
    formData.append('model', model);
    formData.append('condition', condition);
    formData.append('warrantyInformation', warrantyInformation);
    formData.append('shippingInformation', shippingInformation);
    formData.append('returnPolicy', returnPolicy);
    formData.append('specifications', JSON.stringify(specifications));

    images.forEach((image) => {
      formData.append('images', image);
    });

    try {
      if (product) {
        const response = await axios.put(`http://localhost:5000/api/products/${product._id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Product updated successfully!');
      } else {
        const response = await axios.post('http://localhost:5000/api/products/add', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success('Product added successfully!');
      }
      onRequestClose();
    } catch (error) {
      console.error('Error saving product:', error);
      toast.error('Error saving product. Please try again.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="bg-white rounded-lg p-8 shadow-lg w-full max-w-4xl mx-auto mt-20"
    >
      <h2 className="text-2xl font-bold mb-6">{product ? 'Edit Product' : 'Add Product'}</h2>
      <form onSubmit={handleSubmit} className="max-h-[70vh] overflow-y-auto p-4 space-y-4">
        {/* Form fields */}
        <div className="mb-4">
          <label className="block text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Category</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
            required
          >
            <option value="" disabled>Select Category</option>
            {categories.map((cat) => (
              <option key={cat._id} value={cat._id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">Price</label>
            <input
              type="number"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Discount Percentage</label>
            <input
              type="number"
              value={discountPercentage}
              onChange={(e) => setDiscountPercentage(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2"
            />
          </div>
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">Stock</label>
            <input
              type="number"
              value={stock}
              onChange={(e) => setStock(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Brand</label>
            <input
              type="text"
              value={brand}
              onChange={(e) => setBrand(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
        </div>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700">Model</label>
            <input
              type="text"
              value={model}
              onChange={(e) => setModel(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700">Condition</label>
            <input
              type="text"
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              className="w-full border border-gray-300 rounded-lg p-2"
              required
            />
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Warranty Information</label>
          <textarea
            value={warrantyInformation}
            onChange={(e) => setWarrantyInformation(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Shipping Information</label>
          <textarea
            value={shippingInformation}
            onChange={(e) => setShippingInformation(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Return Policy</label>
          <textarea
            value={returnPolicy}
            onChange={(e) => setReturnPolicy(e.target.value)}
            className="w-full border border-gray-300 rounded-lg p-2"
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Specifications</label>
          {specifications.map((spec, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="text"
                value={spec.key}
                onChange={(e) => handleSpecificationChange(index, 'key', e.target.value)}
                className="w-1/2 border border-gray-300 rounded-lg p-2 mr-2"
                placeholder="Key"
                required
              />
              <input
                type="text"
                value={spec.value}
                onChange={(e) => handleSpecificationChange(index, 'value', e.target.value)}
                className="w-1/2 border border-gray-300 rounded-lg p-2 mr-2"
                placeholder="Value"
                required
              />
              <button
                type="button"
                onClick={() => handleRemoveSpecification(index)}
                className="bg-red-500 text-white px-2 py-1 rounded-lg"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddSpecification}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            Add Specification
          </button>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Images</label>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
            className="w-full border border-gray-300 rounded-lg p-2"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onRequestClose}
            className="bg-gray-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
          >
            {product ? 'Update Product' : 'Add Product'}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddProductModal;

// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import axios from 'axios';

// Modal.setAppElement('#root'); // Make sure to bind modal to your appElement (for accessibility)

// const AddProductModal = ({ isOpen, onRequestClose, categories }) => {
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [category, setCategory] = useState('');
//   const [price, setPrice] = useState('');
//   const [discountPercentage, setDiscountPercentage] = useState('');
//   const [stock, setStock] = useState('');
//   const [brand, setBrand] = useState('');
//   const [model, setModel] = useState('');
//   const [condition, setCondition] = useState('');
//   const [warrantyInformation, setWarrantyInformation] = useState('');
//   const [shippingInformation, setShippingInformation] = useState('');
//   const [returnPolicy, setReturnPolicy] = useState('');
//   const [specifications, setSpecifications] = useState([{ key: '', value: '' }]);
//   const [images, setImages] = useState([]);

//   const handleAddSpecification = () => {
//     setSpecifications([...specifications, { key: '', value: '' }]);
//   };

//   const handleRemoveSpecification = (index) => {
//     const updatedSpecs = specifications.filter((_, i) => i !== index);
//     setSpecifications(updatedSpecs);
//   };

//   const handleSpecificationChange = (index, field, value) => {
//     const updatedSpecs = specifications.map((spec, i) =>
//       i === index ? { ...spec, [field]: value } : spec
//     );
//     setSpecifications(updatedSpecs);
//   };

//   const handleImageChange = (e) => {
//     setImages([...e.target.files]);
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('description', description);
//     formData.append('category', category);
//     formData.append('price', price);
//     formData.append('discountPercentage', discountPercentage);
//     formData.append('stock', stock);
//     formData.append('brand', brand);
//     formData.append('model', model);
//     formData.append('condition', condition);
//     formData.append('warrantyInformation', warrantyInformation);
//     formData.append('shippingInformation', shippingInformation);
//     formData.append('returnPolicy', returnPolicy);
//     formData.append('specifications', JSON.stringify(specifications));

//     images.forEach((image) => {
//       formData.append('images', image);
//     });

//     try {
//       const response = await axios.post('http://localhost:5000/api/products/add', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });
//       console.log('Product added:', response.data);
//       onRequestClose();
//     } catch (error) {
//       console.error('Error adding product:', error);
//     }
//   };

//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       className="bg-white rounded-lg p-8 shadow-lg w-full max-w-4xl mx-auto mt-20"
//     >
//       <h2 className="text-2xl font-bold mb-6">Add Product</h2>
//       <form onSubmit={handleSubmit} className="max-h-[70vh] overflow-y-auto p-4 space-y-4">
//         <div className="mb-4">
//           <label className="block text-gray-700">Title</label>
//           <input
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             className="w-full border border-gray-300 rounded-lg p-2"
//             required
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Description</label>
//           <textarea
//             value={description}
//             onChange={(e) => setDescription(e.target.value)}
//             className="w-full border border-gray-300 rounded-lg p-2"
//             required
//           ></textarea>
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Category</label>
//           <select
//             value={category}
//             onChange={(e) => setCategory(e.target.value)}
//             className="w-full border border-gray-300 rounded-lg p-2"
//             required
//           >
//             <option value="" disabled>Select Category</option>
//             {categories.map((cat) => (
//               <option key={cat._id} value={cat._id}>
//                 {cat.name}
//               </option>
//             ))}
//           </select>
//         </div>
//         <div className="mb-4 grid grid-cols-2 gap-4">
//           <div>
//             <label className="block text-gray-700">Price</label>
//             <input
//               type="number"
//               value={price}
//               onChange={(e) => setPrice(e.target.value)}
//               className="w-full border border-gray-300 rounded-lg p-2"
//               required
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700">Discount Percentage</label>
//             <input
//               type="number"
//               value={discountPercentage}
//               onChange={(e) => setDiscountPercentage(e.target.value)}
//               className="w-full border border-gray-300 rounded-lg p-2"
//             />
//           </div>
//         </div>
//         <div className="mb-4 grid grid-cols-2 gap-4">
//           <div>
//             <label className="block text-gray-700">Stock</label>
//             <input
//               type="number"
//               value={stock}
//               onChange={(e) => setStock(e.target.value)}
//               className="w-full border border-gray-300 rounded-lg p-2"
//               required
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700">Brand</label>
//             <input
//               type="text"
//               value={brand}
//               onChange={(e) => setBrand(e.target.value)}
//               className="w-full border border-gray-300 rounded-lg p-2"
//               required
//             />
//           </div>
//         </div>
//         <div className="mb-4 grid grid-cols-2 gap-4">
//           <div>
//             <label className="block text-gray-700">Model</label>
//             <input
//               type="text"
//               value={model}
//               onChange={(e) => setModel(e.target.value)}
//               className="w-full border border-gray-300 rounded-lg p-2"
//               required
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700">Condition</label>
//             <input
//               type="text"
//               value={condition}
//               onChange={(e) => setCondition(e.target.value)}
//               className="w-full border border-gray-300 rounded-lg p-2"
//               required
//             />
//           </div>
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Warranty Information</label>
//           <input
//             type="text"
//             value={warrantyInformation}
//             onChange={(e) => setWarrantyInformation(e.target.value)}
//             className="w-full border border-gray-300 rounded-lg p-2"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Shipping Information</label>
//           <input
//             type="text"
//             value={shippingInformation}
//             onChange={(e) => setShippingInformation(e.target.value)}
//             className="w-full border border-gray-300 rounded-lg p-2"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Return Policy</label>
//           <input
//             type="text"
//             value={returnPolicy}
//             onChange={(e) => setReturnPolicy(e.target.value)}
//             className="w-full border border-gray-300 rounded-lg p-2"
//           />
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Specifications</label>
//           {specifications.map((spec, index) => (
//             <div key={index} className="flex space-x-4 mb-2">
//               <input
//                 type="text"
//                 placeholder="Key"
//                 value={spec.key}
//                 onChange={(e) =>
//                   handleSpecificationChange(index, 'key', e.target.value)
//                 }
//                 className="w-full border border-gray-300 rounded-lg p-2"
//               />
//               <input
//                 type="text"
//                 placeholder="Value"
//                 value={spec.value}
//                 onChange={(e) =>
//                   handleSpecificationChange(index, 'value', e.target.value)
//                 }
//                 className="w-full border border-gray-300 rounded-lg p-2"
//               />
//               <button
//                 type="button"
//                 onClick={() => handleRemoveSpecification(index)}
//                 className="bg-red-500 text-white px-4 py-2 rounded-lg"
//               >
//                 Remove
//               </button>
//             </div>
//           ))}
//           <button
//             type="button"
//             onClick={handleAddSpecification}
//             className="bg-blue-500 text-white px-4 py-2 rounded-lg"
//           >
//             Add Specification
//           </button>
//         </div>
//         <div className="mb-4">
//           <label className="block text-gray-700">Images</label>
//           <input
//             type="file"
//             multiple
//             onChange={handleImageChange}
//             className="w-full border border-gray-300 rounded-lg p-2"
//           />
//         </div>
//         <div className="flex justify-end space-x-4">
//           <button
//             type="button"
//             onClick={onRequestClose}
//             className="bg-gray-500 text-white px-4 py-2 rounded-lg"
//           >
//             Cancel
//           </button>
//           <button
//             type="submit"
//             className="bg-blue-500 text-white px-4 py-2 rounded-lg"
//           >
//             Add Product
//           </button>
//         </div>
//       </form>
//     </Modal>
//   );
// };

// export default AddProductModal;
