import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SellerRegister = () => {
  const [sellerName, setSellerName] = useState('');
  const [sellerEmail, setSellerEmail] = useState('');
  const [sellerPassword, setSellerPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!sellerName) {
      newErrors.sellerName = 'Name is required';
    }
    if (!sellerEmail) {
      newErrors.sellerEmail = 'Email is required';
    }
    if (!sellerPassword) {
      newErrors.sellerPassword = 'Password is required';
    }
    if (sellerPassword !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const response = await axios.post('http://localhost:5000/api/sellers/register', {
          sellerName,
          sellerEmail,
          sellerPassword,
        });

        toast.success('Account created successfully!');
        setTimeout(() => navigate('/login'), 2000); // Redirect to login after successful registration
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
        setErrors((prevErrors) => ({ ...prevErrors, server: errorMessage }));
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section className="bg-[#568AFF] h-screen flex items-center justify-center">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 w-[500px]">
        <div className="mx-auto max-w-screen-sm text-center bg-white p-8 rounded-lg shadow-md">
          <h1 className="text-2xl text-[#202224] font-bold mb-6 text-center">Create an Account</h1>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="sellerName" className="block text-[#202224] mb-2 text-left">Name:</label>
              <input
                type="text"
                id="sellerName"
                className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.sellerName ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-600`}
                placeholder="John Doe"
                value={sellerName}
                onChange={(e) => setSellerName(e.target.value)}
              />
              {errors.sellerName && <p className="text-red-500 text-sm mt-1 text-left">{errors.sellerName}</p>}
            </div>

            <div className="mb-4">
              <label htmlFor="sellerEmail" className="block text-[#202224] mb-2 text-left">Email address:</label>
              <input
                type="email"
                id="sellerEmail"
                className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.sellerEmail ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-600`}
                placeholder="example@example.com"
                value={sellerEmail}
                onChange={(e) => setSellerEmail(e.target.value)}
              />
              {errors.sellerEmail && <p className="text-red-500 text-sm mt-1 text-left">{errors.sellerEmail}</p>}
            </div>

            <div className="mb-4">
              <div className="relative">
                <input
                  type={passwordVisible ? "text" : "password"}
                  id="sellerPassword"
                  className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.sellerPassword ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-600`}
                  placeholder="Enter your password"
                  value={sellerPassword}
                  onChange={(e) => setSellerPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute right-2 top-2 text-gray-700"
                  onClick={togglePasswordVisibility}
                  aria-label={passwordVisible ? "Hide password" : "Show password"}
                >
                  {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {errors.sellerPassword && <p className="text-red-500 text-sm mt-1 text-left">{errors.sellerPassword}</p>}
            </div>

            <div className="mb-4">
              <div className="relative">
                <input
                  type={confirmPasswordVisible ? "text" : "password"}
                  id="confirmPassword"
                  className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 ${errors.confirmPassword ? 'border-red-500' : 'border-gray-300'} focus:ring-blue-600`}
                  placeholder="Confirm your password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute right-2 top-2 text-gray-700"
                  onClick={toggleConfirmPasswordVisibility}
                  aria-label={confirmPasswordVisible ? "Hide password" : "Show password"}
                >
                  {confirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
              {errors.confirmPassword && <p className="text-red-500 text-sm mt-1 text-left">{errors.confirmPassword}</p>}
            </div>

            {errors.server && <p className="text-red-500 text-sm mt-1 text-left">{errors.server}</p>}

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
              disabled={loading}
            >
              {loading ? 'Creating Account...' : 'Create Account'}
            </button>
          </form>

          <div className="mt-4 text-center">
            <span className="text-gray-700">Already have an account? </span>
            <Link to="/seller/login" className="text-blue-600 hover:underline">Login</Link>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default SellerRegister;
