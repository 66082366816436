import React, { useState } from 'react';
import { register } from '../services/authService';
import { useNavigate } from 'react-router-dom';

const Register = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = await register(fullName, email, password, phoneNumber);
      console.log('Registration successful:', data);
      navigate('/login');
    } catch (error) {
      setError('Registration failed. Please try again.');
    }
  };

  return (
    <div className="flex justify-center items-center bg-gray-100 font-[sans-serif] h-full md:min-h-screen p-4">
      <div className="grid justify-center max-w-md mx-auto">
        <div className="bg-white rounded-2xl p-6 -mt-24 relative w-[400px] z-10 shadow-[0_2px_16px_-3px_rgba(6,81,237,0.3)]">
          <h2 className="text-3xl font-extrabold text-blue-600 mb-6">Register</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Full Name</label>
            <input
              type="text"
              className="w-full px-3 py-2 border-b border-gray-300 focus:border-blue-600 outline-none"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              className="w-full px-3 py-2 border-b border-gray-300 focus:border-blue-600 outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              className="w-full px-3 py-2 border-b border-gray-300 focus:border-blue-600 outline-none"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Phone Number</label>
            <input
              type="text"
              className="w-full px-3 py-2 border-b border-gray-300 focus:border-blue-600 outline-none"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2.5 px-4 text-sm font-semibold rounded-full text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
            onClick={handleSubmit}
          >
            Register
          </button>
          {error && <p className="text-red-500 mt-2 text-center">{error}</p>}
          <div className="mt-8 text-center text-sm">
            <p className="text-gray-600">Already have an account?</p>
            <a href="/login" className="text-blue-600 hover:underline">
              Sign in
            </a>
          </div>
        </div>
        <img
          src="https://readymadeui.com/login-image.webp"
          className="absolute bottom-0 left-0 w-full object-cover"
          alt="login-image"
        />
      </div>
    </div>
  );
};

export default Register;
