import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center mb-8">Privacy Policy</h2>
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4">1. Introduction</h3>
          <p className="mb-4">
            Welcome to Mylapkart. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website or use our services.
          </p>
          <h3 className="text-lg font-semibold mb-4">2. Information We Collect</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Personal Information: We may collect personal information that you provide to us, such as your name, email address, phone number, shipping address, billing information, and other details necessary for processing orders and providing customer support.</li>
            <li>Non-Personal Information: We may also collect non-personal information about your visit, including your IP address, browser type, operating system, referring URLs, and information about your use of our website through cookies and similar technologies.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">3. How We Use Your Information</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Processing Orders: To process and fulfill your orders, including sending confirmation emails, shipping products, and providing customer support.</li>
            <li className="mb-2">Improving Services: To understand how our website and services are being used, allowing us to improve and customize our offerings.</li>
            <li className="mb-2">Marketing Communications: To send you promotional materials and newsletters, which you can opt out of at any time.</li>
            <li>Compliance: To comply with legal obligations and protect our rights and the rights of our users.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">4. Sharing Your Information</h3>
          <p className="mb-4">
            We do not sell, trade, or otherwise transfer your personal information to third parties without your consent, except in the following circumstances:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Service Providers: We may share your information with third-party service providers who assist us in operating our website, conducting our business, or providing services to you, so long as those parties agree to keep this information confidential.</li>
            <li className="mb-2">Legal Requirements: We may disclose your information when required by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
            <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">5. Data Security</h3>
          <p className="mb-4">
            We implement a variety of security measures to maintain the safety of your personal information. While we strive to use commercially acceptable means to protect your information, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>
          <h3 className="text-lg font-semibold mb-4">6. Your Rights and Choices</h3>
          <ul className="list-disc list-inside mb-4">
            <li className="mb-2">Access and Correction: You have the right to access and correct your personal information. You can update your account information by logging into your account on our website.</li>
            <li className="mb-2">Opt-Out: You can opt out of receiving marketing communications from us by following the unsubscribe instructions included in each email or by contacting us directly.</li>
            <li>Cookies: Most web browsers are set to accept cookies by default. You can choose to set your browser to remove or reject cookies, but this may affect your ability to use some features of our website.</li>
          </ul>
          <h3 className="text-lg font-semibold mb-4">7. Third-Party Links</h3>
          <p className="mb-4">
            Our website may contain links to third-party websites. We are not responsible for the privacy practices or the content of these websites. We encourage you to review the privacy policies of any third-party websites you visit.
          </p>
          <h3 className="text-lg font-semibold mb-4">8. Children's Privacy</h3>
          <p className="mb-4">
            Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently received personal information from a child under 18, we will delete such information from our records.
          </p>
          <h3 className="text-lg font-semibold mb-4">9. Changes to This Privacy Policy</h3>
          <p className="mb-4">
            We may update this Privacy Policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the new Privacy Policy on our website. Your continued use of our services after the posting of changes constitutes your acceptance of such changes.
          </p>
          <h3 className="text-lg font-semibold mb-4">10. Contact Us</h3>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Email: <a href="mailto:support@mylapkart.com" className="text-blue-600">support@mylapkart.com</a></li>
            {/* <li>Phone: +91 72196 55222</li> */}
            <li>Mailing Address: Mylapkart, Shop No. 26, First Floor, Navkar Plaza, Waki Road, Jamner, Tal-Jamner, Dist-Jalgaon 424206 (IN-MH)</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
