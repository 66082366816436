// components/ViewOrderDetails.js

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

const ViewOrderDetails = () => {
  const { orderId } = useParams(); // Get orderId from URL
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/orders/${orderId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
        });
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order details:', error);
        setError('Error fetching order details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [orderId]);

  if (loading) return <div className="text-center py-4">Loading...</div>;

  if (error) return <div className="text-red-500 py-4 text-center">{error}</div>;

  if (!order) return <div className="text-center py-4">No order found.</div>;

  return (
    <div className="font-sans bg-gray-100 p-4 md:p-6 lg:p-8 xl:p-10 lg:max-w-6xl max-w-4xl mx-auto rounded-lg shadow-lg">
      <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-6">
        <h1 className="text-2xl md:text-3xl font-extrabold text-gray-900 mb-4 lg:mb-0">Order Details</h1>
        <p className="text-lg md:text-xl font-medium text-gray-600">
          Order ID: <span className="font-bold text-gray-900">{order.orderID}</span>
        </p>
      </div>
      <div className="bg-white p-4 md:p-6 lg:p-8 rounded-lg shadow-md">
        <div className="mb-6 border-b border-gray-200 pb-6">
          <h2 className="text-lg md:text-xl font-semibold text-gray-800 mb-2">
            Status: 
            <span className={`font-semibold ${order.orderStatus === 'Delivered' ? 'text-green-600' : 'text-yellow-600'}`}>
              {order.orderStatus}
            </span>
          </h2>
          <p className="text-base md:text-lg text-gray-700 mb-2">Total Amount: <span className="font-bold text-gray-900">₹{order.totalAmount}</span></p>
          <p className="text-base md:text-lg text-gray-700 mb-4">Payment Method: <span className="font-bold text-gray-900">{order.paymentMethod}</span></p>
          <p className="text-base md:text-lg text-gray-700 mb-4">Order Date: <span className="font-bold text-gray-900">{order.createdAt ? format(parseISO(order.createdAt), 'dd MMM yyyy') : 'N/A'}</span></p>
        </div>
        <h3 className="text-lg md:text-xl font-semibold text-gray-800 mb-4">Items:</h3>
        <ul className="space-y-4">
          {order.orderItems.map((item) => (
            <li key={item.productId._id} className="flex flex-col md:flex-row items-start md:items-center bg-gray-50 p-4 rounded-lg shadow-sm">
              <img
                src={item.productId.images[0]}
                alt={item.productId.title}
                className="w-24 h-24 md:w-32 md:h-32 object-cover mb-4 md:mb-0 mr-0 md:mr-4 rounded-md"
              />
              <div className="flex-1">
                <h4 className="text-base md:text-lg font-medium text-gray-800">{item.productId.title}</h4>
                <p className="text-sm md:text-base text-gray-600">Price: ₹{item.price}</p>
                <p className="text-sm md:text-base text-gray-600">Quantity: {item.quantity}</p>
              </div>
            </li>
          ))}
        </ul>
        {order.orderStatus === 'Delivered' && (
          <a
            href={`/api/orders/${orderId}/invoice`}
            className="block mt-6 text-center bg-green-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700 transition duration-300"
            download
          >
            Download Invoice
          </a>
        )}
        <button
          onClick={() => navigate('/')}
          className="block mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          Back to Home
        </button>
      </div>
      {/* Mobile Filter and Search Bar */}
      <div className="fixed bottom-0 left-0 w-full bg-white border-t border-gray-200 shadow-lg md:hidden">
        <div className="flex flex-col p-4">
          <button
            onClick={() => console.log('Filter clicked')}
            className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Filter
          </button>
          <input
            type="text"
            placeholder="Search..."
            className="mt-2 border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewOrderDetails;



// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { useParams } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';
// import { format, parseISO } from 'date-fns';

// const ViewOrderDetails = () => {
//   const { orderId } = useParams(); // Get orderId from URL
//   const [order, setOrder] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchOrderDetails = async () => {
//       try {
//         const response = await axios.get(`http://localhost:5000/api/orders/${orderId}`, {
//           headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` }
//         });
//         setOrder(response.data);
//       } catch (error) {
//         console.error('Error fetching order details:', error);
//         setError('Error fetching order details. Please try again later.');
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchOrderDetails();
//   }, [orderId]);

//   if (loading) return <div className="text-center py-4">Loading...</div>;

//   if (error) return <div className="text-red-500 py-4 text-center">{error}</div>;

//   if (!order) return <div className="text-center py-4">No order found.</div>;

//   return (
//     <div className="font-sans bg-gray-100 p-6 lg:max-w-6xl max-w-4xl mx-auto rounded-lg shadow-lg">
//       <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-6">
//         <h1 className="text-3xl font-extrabold text-gray-900 mb-4 lg:mb-0">Order Details</h1>
//         <p className="text-lg font-medium text-gray-600">
//           Order ID: <span className="font-bold text-gray-900">{order.orderID}</span>
//         </p>
//       </div>
//       <div className="bg-white p-6 rounded-lg shadow-md">
//         <div className="mb-6 border-b border-gray-200 pb-6">
//           <h2 className="text-2xl font-semibold text-gray-800 mb-2">Status: 
//             <span className={`font-semibold ${order.orderStatus === 'Delivered' ? 'text-green-600' : 'text-yellow-600'}`}>
//               {order.orderStatus}
//             </span>
//           </h2>
//           <p className="text-lg text-gray-700 mb-2">Total Amount: <span className="font-bold text-gray-900">₹{order.totalAmount}</span></p>
//           <p className="text-lg text-gray-700 mb-4">Payment Method: <span className="font-bold text-gray-900">{order.paymentMethod}</span></p>
//           <p className="text-lg text-gray-700 mb-4">Order Date: <span className="font-bold text-gray-900">{order.createdAt ? format(parseISO(order.createdAt), 'dd MMM yyyy') : 'N/A'}</span></p>
//         </div>
//         <h3 className="text-xl font-semibold text-gray-800 mb-4">Items:</h3>
//         <ul className="space-y-4">
//           {order.orderItems.map((item) => (
//             <li key={item.productId._id} className="flex items-center bg-gray-50 p-4 rounded-lg shadow-sm">
//               <img
//                 src={item.productId.images[0]}
//                 alt={item.productId.title}
//                 className="w-20 h-20 object-cover mr-4 rounded-md"
//               />
//               <div className="flex-1">
//                 <h4 className="text-lg font-medium text-gray-800">{item.productId.title}</h4>
//                 <p className="text-sm text-gray-600">Price: ₹{item.price}</p>
//                 <p className="text-sm text-gray-600">Quantity: {item.quantity}</p>
//               </div>
//             </li>
//           ))}
//         </ul>
//         {order.orderStatus === 'Delivered' && (
//           <a
//             href={`/api/orders/${orderId}/invoice`}
//             className="block mt-6 text-center bg-green-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-green-700 transition duration-300"
//             download
//           >
//             Download Invoice
//           </a>
//         )}
//         <button
//           onClick={() => navigate('/')}
//           className="block mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
//         >
//           Back to Home
//         </button>
//       </div>
//     </div>
//   );
// };

// export default ViewOrderDetails;
