import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';

const ProductDetails = () => {
  const navigate = useNavigate();

  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);

  useEffect(() => {
    if (productId) {
      // Fetch product details
      axios
        .get(`http://localhost:5000/api/products/${productId}`)
        .then((response) => {
          setProduct(response.data);
          setSelectedImage(response.data.images[0]); // Set the first image as the selected image
        })
        .catch((error) => {
          console.error('Error fetching product details:', error.message);
          setError('An error occurred while fetching the product details.');
        });
    } else {
      setError('Invalid product ID.');
    }
  }, [productId]);

  
// Fetch similar products
useEffect(() => {
  if (product?.category) {
    axios
      .get('http://localhost:5000/api/products/similar', {
        params: {
          categoryId: product.category, // Pass the category ID
          excludeId: productId, // Pass the product ID to exclude
        },
      })
      .then((response) => {
        setSimilarProducts(response.data);
      })
      .catch((error) => {
        console.error('Error fetching similar products:', error.message);
      });
  }
}, [product, productId]);



  if (error) {
    return <div>Error fetching product details: {error}</div>;
  }

  if (!product) {
    return <div>Loading...</div>;
  }

  const {
    title,
    description,
    category,
    price,
    discountPercentage,
    rating,
    numReviews,
    stock,
    brand,
    model,
    warrantyInformation,
    shippingInformation,
    returnPolicy,
    condition,
    specifications,
    images,
    reviews,
  } = product;

  const discountedPrice = price - price * (discountPercentage / 100);
  const handleAddToCart = async (productId) => {
    try {
      await axios.post(
        "http://localhost:5000/api/cart/add",
        { productId: productId, quantity: 1 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Product added to cart successfully!',
      });
      // Update the cart item count in the Navbar
      // updateCartCount(response.data.items.length);
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add product to cart.',
      });
    }
  };

  const handleBuyNow = async (productId) => {
    try {
      await axios.post(
        "http://localhost:5000/api/cart/add",
        { productId: productId, quantity: 1 },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      navigate('/buy-now');
      // Update the cart item count in the Navbar
      // updateCartCount(response.data.items.length);
    } catch (error) {
      console.error("Error adding product to cart:", error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to add product to cart.',
      });
    }
  };
  return (
    <div className="container mx-auto p-6">
      <div className="flex flex-wrap md:flex-nowrap -mx-3">
        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          {/* Main Image */}
          {selectedImage ? (
            <img
              src={selectedImage}
              alt={title}
              className="w-full h-80 object-contain rounded-lg mb-4"
            />
          ) : (
            <div className="w-full h-80 bg-gray-200 rounded-lg flex items-center justify-center mb-4">
              No Image Available
            </div>
          )}
          {/* Thumbnails */}
          <div className="flex space-x-2 overflow-x-auto">
            {images && images.length > 0 ? (
              images.map((image, index) => (
                <div
                  key={index}
                  className={`w-20 h-20 overflow-hidden border-2 rounded-lg cursor-pointer transition-transform duration-200 transform hover:scale-105 ${
                    selectedImage === image ? 'border-blue-500' : 'border-transparent'
                  }`}
                  onClick={() => setSelectedImage(image)}
                >
                  <img
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    className="w-full h-full object-cover"
                  />
                </div>
              ))
            ) : (
              <p>No Images Available</p>
            )}
          </div>
        </div>
        <div className="w-full md:w-2/3 px-3">
          <h1 className="text-3xl font-bold mb-4">{title}</h1>
          <p className="text-gray-700 mb-4">{description}</p>
          <p className="text-xl font-bold mb-4">
            ${discountedPrice.toFixed(2)}{' '}
            <span className="text-gray-500 line-through">${price.toFixed(2)}</span>
          </p>

          <div className="flex items-center mb-4">
            <div className="flex items-center">
              {Array.from({ length: 5 }, (_, index) => (
                <svg
                  key={index}
                  className={`w-5 h-5 ${
                    index < rating ? 'text-yellow-500' : 'text-gray-300'
                  }`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 15l-5.5 2.9 1-5.8L1 8.1l5.9-.9L10 2l3.1 5.2 5.9.9-4.4 4.1 1 5.8z" />
                </svg>
              ))}
            </div>
            <p className="text-gray-700 ml-2">({numReviews} Reviews)</p>
          </div>

          <div className="mt-4 mb-6">
            <button
            onClick={() => handleAddToCart(product._id)} 
            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
              Add to Cart
            </button>
            <button 
            onClick={() => handleBuyNow(product._id)}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 ml-2">
              Buy Now
            </button>
            <button className="bg-orange-500 text-white py-2 px-4 rounded hover:bg-orange-600 ml-2">
              Buy on EMI
            </button>
          </div>

          <p className="text-gray-700 mb-4">
            {stock > 0 ? (
              <>
                <span>In Stock</span>
                {stock < 10 && (
                  <span className="text-red-500"> - Hurry, only a few left!</span>
                )}
              </>
            ) : (
              <span className="text-red-500">Not Available</span>
            )}
          </p>

          {specifications && (
            <div className="mb-4">
            <h3 className="text-xl font-bold mb-4">Specifications</h3>
            <div className="bg-white shadow-md rounded-lg p-4">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    <th className="border-b-2 border-gray-300 py-2 text-left font-semibold text-gray-700">Attribute</th>
                    <th className="border-b-2 border-gray-300 py-2 text-left font-semibold text-gray-700">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {specifications && Object.entries(specifications).map(([key, value]) => (
                    <tr key={key}>
                      <td className="border-b border-gray-200 py-2 font-semibold text-gray-700">{key}</td>
                      <td className="border-b border-gray-200 py-2 text-gray-600">{value}</td>
                    </tr>
                  ))}
                  <tr>
                    <td className="border-b border-gray-200 py-2 font-semibold text-gray-700">Condition</td>
                    <td className="border-b border-gray-200 py-2 text-gray-600">{condition}</td>
                  </tr>
                  <tr>
                    <td className="border-b border-gray-200 py-2 font-semibold text-gray-700">Warranty Information</td>
                    <td className="border-b border-gray-200 py-2 text-gray-600">{warrantyInformation}</td>
                  </tr>
                  <tr>
                    <td className="border-b border-gray-200 py-2 font-semibold text-gray-700">Shipping Information</td>
                    <td className="border-b border-gray-200 py-2 text-gray-600">{shippingInformation}</td>
                  </tr>
                  <tr>
                    <td className="border-b border-gray-200 py-2 font-semibold text-gray-700">Return Policy</td>
                    <td className="border-b border-gray-200 py-2 text-gray-600">{returnPolicy}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          )}
        </div>
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Reviews</h2>
        {reviews && reviews.length > 0 ? (
          <ul>
            {reviews.map((review) => (
              <li key={review._id} className="mb-4 p-4 bg-gray-100 rounded-lg">
                <p className="font-bold">{review.user.name}</p>
                <p className="text-gray-700">{review.comment}</p>
                <div className="flex">
                  {Array.from({ length: 5 }, (_, index) => (
                    <svg
                      key={index}
                      className={`w-4 h-4 ${
                        index < review.rating ? 'text-yellow-500' : 'text-gray-300'
                      }`}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 15l-5.5 2.9 1-5.8L1 8.1l5.9-.9L10 2l3.1 5.2 5.9.9-4.4 4.1 1 5.8z" />
                    </svg>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No reviews yet.</p>
        )}
      </div>

      <div className="mt-8">
        <h2 className="text-2xl font-bold mb-4">Similar Products</h2>
        <div className="flex flex-wrap -mx-3">
          {similarProducts.map((similarProduct) => (
            <div key={similarProduct._id} className="w-full sm:w-1/2 lg:w-1/4 px-3 mb-6">
              <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <img
                  src={similarProduct.images[0]}
                  alt={similarProduct.title}
                  className="w-full h-48 object-cover"
                />
                <div className="p-4">
                  <h3 className="text-lg font-semibold mb-2">{similarProduct.title}</h3>
                  <p className="text-gray-600 mb-2">${similarProduct.price.toFixed(2)}</p>
                  <button className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600">
                    View Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;














// import React, { useEffect, useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import ImageCarousel from '../components/ImageCarousel';
// import { FaCartPlus } from 'react-icons/fa';

// const ProductDetails = () => {
//   const { productId } = useParams();
//   const [product, setProduct] = useState(null);
//   const navigate = useNavigate();
//   console.log("productId", productId);

//   useEffect(() => {
//     const fetchProduct = async () => {
//       try {
//         const response = await axios.get(`http://localhost:5000/api/products/${productId}`);
//         setProduct(response.data);
//       } catch (error) {
//         console.error('Error fetching product details:', error.message);
//       }
//     };

//     fetchProduct();
//   }, [productId]);

//   const handleAddToCart = async () => {
//     try {
//       await axios.post(
//         'http://localhost:5000/api/cart/add', 
//         { productId: product._id, quantity: 1 }, 
//         { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
//       );
//       // Update the cart item count in the Navbar
//       // updateCartCount(response.data.items.length);
//     } catch (error) {
//       console.error('Error adding product to cart:', error.message);
//     }
//   };

//   if (!product) {
//     return <p>Loading...</p>;
//   }

//   return (
//     <div className="container mx-auto p-4">
//       <div className="bg-white shadow-md rounded-lg overflow-hidden">
//         <ImageCarousel images={product.images} /> 
//         <div className="p-4">
//           <h3 className="text-lg font-semibold">{product.title}</h3>
//           <p className="text-gray-600">{product.description}</p>
//           <div className="mt-2 text-xl font-bold">${product.price}</div>
//           <p className="text-gray-600 mt-2">Brand: {product.brand}</p>
//           <p className="text-gray-600 mt-2">Model: {product.model}</p>
//           <p className="text-gray-600 mt-2">Condition: {product.condition}</p>
//           <p className="text-gray-600 mt-2">Category: {product.category?.name}</p>
//           <p className="text-gray-600 mt-2">Warranty Information: {product.warrantyInformation}</p>
//           <p className="text-gray-600 mt-2">Shipping Information: {product.shippingInformation}</p>
//           <p className="text-gray-600 mt-2">Return Policy: {product.returnPolicy}</p>
//           <div className="mt-2">
//             <h4 className="text-lg font-semibold">Specifications</h4>
//             <ul className="list-disc pl-5">
//               {Object.entries(product.specifications).map(([key, value]) => (
//                 <li key={key} className="mb-2">
//                   <span className="font-bold">{key}: </span>{value}
//                 </li>
//               ))}
//             </ul>
//           </div>
//           <div className="mt-4 flex justify-between">
//             <button
//               className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center hover:bg-green-600 transition duration-300"
//               onClick={handleAddToCart}
//             >
//               <FaCartPlus className="mr-2" />
//               Add to Cart
//             </button>
//             <button
//               className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
//               onClick={() => navigate('/buy-on-emi', { state: { product } })}
//             >
//               Buy on EMI
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductDetails;
